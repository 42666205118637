import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSuccess, logout } from '../store/authSlice';
import AxiosController from '../api/apiController';

const useAuth = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (isAuthenticated) {
        try {
          const response = await AxiosController.get('/v1/user/profile')
          dispatch(userSuccess({
            user: response.data,
          }));
        } catch (error) {
          console.error('Profil alınamadı:', error);
          dispatch(logout());
        }
      }
    };

    fetchUserProfile();
  }, [dispatch, token, isAuthenticated]);

  return {
    user: useSelector((state) => state.auth.user),
    isAuthenticated,
  };
};

export default useAuth;
