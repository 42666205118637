import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosCmsController, { cmsImageUrl } from "../../api/apiControllerCms";
import moment from 'moment'
import 'moment/locale/tr';
moment.locale('tr');

const Blog = () => {
  const [firstBlog, setFirstBlog] = useState({});
  const [featuredBlogs, setFeaturedBlogs] = useState([]);
  const [featuredBlogsTotal, setFeaturedBlogsTotal] = useState(0);
  const [featuredBlogstotalPages, setFeaturedBlogsTotalPages] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const blogsPerPage = 6;

  const fetchFeaturedBlogs = async () => {
    try {
      const response = await AxiosCmsController.get('/api/blogs?filters[type][$eq]=onecikan&populate=*');
      setFirstBlog(response.data[0]);
      setFeaturedBlogs(response.data?.slice(1));
      setFeaturedBlogsTotalPages(response.meta.pagination.pageCount);
      setFeaturedBlogsTotal(response.meta.pagination.total);
    } catch (error) {
      console.error("Error fetching featured blogs:", error);
    }
  };

  const fetchBlogs = async (page = 1) => {
    try {
      const response = await AxiosCmsController.get(`/api/blogs?filters[type][$eq]=default&pagination[page]=${page}&pagination[pageSize]=${blogsPerPage}&populate=*`);
      setBlogs(response.data);
      setTotalPages(response.meta.pagination.pageCount);
      setTotal(response.meta.pagination.total);
    } catch (error) {
      console.error("Error fetching all blogs:", error);
    }
  };

  useEffect(() => {
    fetchFeaturedBlogs();
  }, [])

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className=" lg:container lg:mx-auto">
      <div className="flex flex-col">
        {featuredBlogsTotal > 0 && <div className="flex flex-col lg:flex-row items-center gap-[32px]">
          <div>
            <img
              src={cmsImageUrl + firstBlog?.image?.url}
              alt="blog-car"
              className="rounded-[32px] w-full h-full"
            />
          </div>
          <div className="flex flex-col lg:w-[706px]">
            <h6 className="font-bold lg:text-[32px] text-[#18181C]">
              {firstBlog?.title}
            </h6>
            <article className="text-sm lg:text-base mt-[8px] text-[#18181C] text-opacity-60 line-clamp-5">
              {firstBlog?.shortDesc}
            </article>
            <Link to={'/blog/' + firstBlog?.slug} className="mt-[16px] lg:mt-[32px] self-start bg-[#FFEB00] flex gap-2 items-center rounded-[40px]  px-[12px] py-[12px] text-[#09090B] font-semibold">
              <span>Yazıyı Oku</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow-icon" />
            </Link>
          </div>
        </div>
        }
        {featuredBlogsTotal > 1 && < div className="mt-[28px] lg:mt-[56px] flex flex-col">
          <h6 className="font-semibold text-[24px] text-[#1E1E1E]">Öne Çıkan Yazılar</h6>
          <div className="grid grid-cols-1 lg:grid-cols-3 mt-[8px] lg:mt-[16px] gap-y-10 lg:gap-y-0 lg:gap-x-[24px]">
            {featuredBlogs?.map((item, index) => (
              <Link to={'/blog/' + item?.slug} key={index} className="flex flex-col w-full">
                <img src={cmsImageUrl + item?.image?.url} alt="blog-img" className="rounded-[16px] h-[240px] w-full object-cover" />
                <h6 className="font-medium text-[#09090B] text-[18px] mt-[16px]  line-clamp-2">
                  {item?.title}
                </h6>
                <span className="mt-[8px] text-[#3F3F46] text-sm text-opacity-60  line-clamp-2">
                  {item?.shortDesc}
                </span>
                <span className="mt-[16px] text-[#71717A] text-sm text-opacity-60">
                  {moment(item?.publishedAt).format('D MMMM YYYY, HH:mm')}
                </span>
              </Link>
            ))}
          </div>
        </div>}
        {total > 0 && <div>
          <div className="mt-[56px] flex flex-col">
            <h6 className="font-semibold text-[24px] text-[#1E1E1E]">Tüm Blog</h6>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-[8px] lg:mt-[16px] gap-10">
              {blogs?.map((item, index) => (
                <Link to={'/blog/' + item?.slug} key={index} className="flex flex-col w-full">
                  <img src={cmsImageUrl + item?.image?.url} alt="blog-img" className="rounded-[16px]  h-[240px] w-full object-cover" />
                  <h6 className="font-medium text-[#09090B] text-[18px] mt-[16px]  line-clamp-2">
                    {item?.title}
                  </h6>
                  <span className="mt-[8px] text-[#3F3F46] text-sm text-opacity-60  line-clamp-2">
                    {item?.shortDesc}
                  </span>
                  <span className="mt-[16px] text-[#71717A] text-sm text-opacity-60">
                    {moment(item?.publishedAt).format('D MMMM YYYY, HH:mm')}
                  </span>
                </Link>
              ))}
            </div>
          </div>
          <div className="mt-[32px] flex justify-center">
            <nav className="pagination">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={`px-4 py-2 mx-1 rounded ${currentPage === number ? 'bg-black text-white' : 'bg-gray-300 text-black'}`}
                >
                  {number}
                </button>
              ))}
            </nav>
          </div>
        </div>
        }
      </div>
    </div >
  );
};

export default Blog;
