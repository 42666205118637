import React, { useEffect, useState, useRef } from 'react';
import AxiosController, { socketbaseUrl } from '../../api/apiController';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/FormatCurrency';

const AuctionBidLast = ({ auctionId }) => {
  const token = useSelector((state) => state.auth.token);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [lastPrice, setLastPrice] = useState(0);
  const ws = useRef(null); // Use useRef to store the WebSocket instance

  // const formatCurrency = (value) => {
  //   return new Intl.NumberFormat('tr-TR').format(value) + ' TL';
  // };

  const fetchCurrentPrice = async () => {
    try {
      const response = await AxiosController.get(`/v1/auction/bid/${auctionId}/current_price`);
      if (response) {
        setCurrentPrice(response.current_price);
        setLastPrice(response.last_bid_amount);
      }
    } catch (error) {
      console.error('Error fetching current price:', error);
    }
  };

  useEffect(() => {
    fetchCurrentPrice();
  }, [auctionId]);

  useEffect(() => {
    // If WebSocket is already created, do not create a new one
    if (ws.current) {
      return;
    }

    // Correctly construct the WebSocket URL

    const wsUrl = `${socketbaseUrl}/v1/auction/bid/ws/${auctionId}?token=${token}`;

    // Create the WebSocket connection
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onmessage = (event) => {
      const newBid = JSON.parse(event.data);
      if (newBid.type === 'new_bid') {
        setCurrentPrice(newBid.data.current_price);
        setLastPrice(newBid.data.amount);
        message.destroy()
        message.success(
          `Yeni teklif: ${newBid.data.amount.toLocaleString('tr-TR', {
            style: 'currency',
            currency: 'TRY',
          })}`
        );
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket disconnected');
    };

    // Clean up the WebSocket connection on unmount
    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, [auctionId]); // Only re-run if auctionId changes

  return (
    <div className="flex items-center gap-2.5 lg:mt-[16px]">
      <div className="flex flex-col">
        <span className="font-bold text-[#09090B] text-[20px]">
          {formatCurrency(currentPrice)}
        </span>
        <span className="font-bold text-green-500 text-[14px]">
          {formatCurrency(lastPrice)}
        </span>
      </div>
      <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px] text-[#09090B] font-semibold text-sm">
        Son Gelen Teklif
      </span>
    </div>
  );
};

export default AuctionBidLast;
