import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import AxiosCmsController, { cmsImageUrl } from "../../api/apiControllerCms";
const Contact = () => {
  const [form] = Form.useForm();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosCmsController.get("/api/contact-us?populate[ContactUsItems][populate]=icon")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching about us data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onFinish = (values) => {
    console.log("Form values:", values);
    message.success("Form başarıyla gönderildi!");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Form gönderilemedi.");
  };

  return (
    <div className="lg:container  lg:mx-auto">
      <div className="flex flex-col-reverse lg:flex-col">
        <div className="flex flex-col lg:flex-row lg:justify-around">
          {data?.ContactUsItems?.map((item, index) => (
            <div key={index} className="flex flex-col gap-4 items-center">
              <div className="flex items-center justify-center w-[80px] h-[80px] bg-[#FFEB00] rounded-[20px]">
                <img
                  src={cmsImageUrl + item?.icon?.url}
                  alt={item?.icon?.name}
                  className="w-[32px] h-[32px]"
                />
              </div>
              <div className="flex flex-col gap-0 items-center">
                <h4 className="font-semibold text-[#09090B] text-[24px]">
                  {item?.Value}
                </h4>
                <span className="text-sm text-[#52525B] max-w-[150px] text-center leading-loose">
                  {item?.Desc}
                </span>
              </div>
            </div>

          ))}
        </div>
        <div className="flex flex-col lg:flex-row lg:mt-[60px]">
          <div
            className="text-[34px] w-1/2 rounded-[24px]"
            dangerouslySetInnerHTML={{
              __html: data?.iframe
                ?.replace(
                  "<iframe ",
                  '<iframe  class="rounded-[24px] w-[340px] h-[250px] lg:h-full lg:w-full"  height="400"'
                )
                ?.replace('width="1200" ', ' "'),
            }}
          ></div>
          <div className="mt-5 lg:mt-0 lg:ml-[20px] 2xl:ml-[40px] ">
            <h5 className="font-semibold text-lg text-[#18182C] leading-snug">
              İletişim Formu
            </h5>
            <Form
              form={form} // Form nesnesini ilet
              name="contact_form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              style={{ maxWidth: "600px", margin: "0 auto" }}
            >
              <div className="grid lg:grid-cols-2 lg:gap-x-20 ">
                <Form.Item
                  label="İsim Soyisim"
                  name="fullName"
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Lütfen isim ve soyisim girin!",
                    },
                  ]}
                >
                  <Input
                    placeholder="İsim ve Soyisim"
                    className="rounded-[12px] border border-gray-200 lg:mt-0 text-zinc-600 py-[20px] px-[24px] w-[340px] lg:w-[314px]"
                  />
                </Form.Item>

                <Form.Item
                  label="Telefon Numarası"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen telefon numarası girin!",
                    },
                  ]}
                >
                  <Input placeholder="Telefon Numarası" className="rounded-[12px] border border-gray-200 text-zinc-600 py-[20px] px-[24px] w-[340px] lg:w-[314px]" />
                </Form.Item>

                <Form.Item
                  label="Konu Başlığı"
                  name="subject"
                  className="-mt-3"
                  rules={[
                    { required: true, message: "Lütfen konu başlığını girin!" },
                  ]}
                >
                  <Input placeholder="Konu Başlığı" className="rounded-[12px] border border-gray-200 text-zinc-600 py-[20px] px-[24px] w-[340px] lg:w-[314px]" />
                </Form.Item>

                <Form.Item
                  label="Mail Adresi"
                  name="email"
                  className="-mt-3"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen mail adresinizi girin!",
                    },
                    {
                      type: "email",
                      message: "Geçerli bir e-posta adresi girin!",
                    },
                  ]}
                >
                  <Input placeholder="Mail Adresi" className="rounded-[12px] border border-gray-200 text-zinc-600 py-[20px] px-[24px] w-[340px] lg:w-[314px]" />
                </Form.Item>
              </div>

              <Form.Item
                label="Açıklama"
                name="message"
                rules={[{ required: true, message: "Lütfen açıklama girin!" }]}
                className="-mt-3"
              >
                <Input.TextArea placeholder="Açıklama" rows={4} className="w-[370px] lg:min-w-[660px]" style={{ resize: 'none' }} />
              </Form.Item>

              <Form.Item
                name="privacy"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                          "KVKK kapsamında verilerimin işlenmesine onay vermeniz gerekiyor!"
                        ),
                  },
                ]}
              >
                <Checkbox className="text-xs text-[#18181C]">
                  KVKK kapsamında verilerimin işlenmesine onay veriyorum
                </Checkbox>
              </Form.Item>

              <Form.Item className="-mt-3">
                <Button type="primary" htmlType="submit" className="bg-[#FFEB00] w-[340px] lg:w-[660px] py-[20px] px-[35px] text-zinc-600 h-[56px] rounded-[12px] text-[#09090B] font-semibold">
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
