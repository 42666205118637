import axios from "axios";
import { message } from "antd"; 
// import { store } from '../store';

const AxiosCmsController = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

AxiosCmsController.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosCmsController.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      const status = error.response.status;
      const data = error.response.data;

      if (status === 401) {
        // Yetkisiz erişim, kullanıcıyı çıkış yapıyoruz
        // store.dispatch(logout());
      } else {
        // Hata mesajını 'detail' alanından alıyoruz
        const errorMessage = data.detail || 'Bir hata oluştu';

        // Hata mesajını global olarak gösteriyoruz
        message.error(errorMessage);
      }
    } else {
      // Ağ hatası veya yanıt olmayan diğer hatalar
      message.error('Ağ hatası, lütfen daha sonra tekrar deneyiniz.');
    }

    return Promise.reject(error);
  }
);

export const cmsBaseUrl = process.env.REACT_APP_CMS_API_URL;
export const cmsImageUrl = process.env.REACT_APP_CMS_IMAGE_URL;

export default AxiosCmsController;
