import React from 'react'

const SaleTab = () => {
  return (
    <div>
  asd
    </div>
  )
}

export default SaleTab