import React from 'react';
import AxiosController from '../../api/apiController';
import { formatCurrency } from '../../utils/FormatCurrency';

const AuctionBidAdd = ({ auctionId, detailCar }) => {
  // const formatCurrency = (value) => {
  //   return new Intl.NumberFormat('tr-TR').format(value) + ' TL';
  // };

  const handleBid = async () => {
    try {
      const response = await AxiosController.post('/v1/auction/bid/', {
        auction_id: auctionId,
        amount: detailCar.min_increment,  
      });
    } catch (error) {
      console.error('Error placing bid:', error);
    }
  };

  return (
    <button
      className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center"
      onClick={handleBid}
    >
      <span className="text-[#09090B] font-semibold">
        {formatCurrency(detailCar.min_increment)} Arttır
      </span>
    </button>
  );
};

export default AuctionBidAdd;
