// src/pages/ProfilePage.js

import React, { useState } from 'react';
import PremiumTab from './PremiumTab';
import ReceivedTab from './ReceivedTab';
import SaleTab from './SaleTab';
import CarDriverPersonTab from './CarDriverPersonTab';
import ProvisionTab from './ProvisionTab';
import MyProfileTab from './MyProfileTab';
import ContactTab from './ContactTab';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';


const Profile = () => {
  const [activeMenu, setActiveMenu] = useState('premium');

  const renderContent = () => {
    switch (activeMenu) {
      case 'premium':
        return <PremiumTab />;
      case 'aldiklarim':
        return <ReceivedTab />;
      case 'sattiklarim':
        return <SaleTab />;
      case 'araciAlacakKisi':
        return <CarDriverPersonTab />;
      case 'provizyonBilgilerim':
        return <ProvisionTab />;
      case 'profilBilgilerim':
        return <MyProfileTab />;
      case 'iletisimTercihlerim':
        return <ContactTab />;
      default:
        return <div>Seçili bir menü yok</div>;
    }
  };

  return (
    <div className='container mx-auto'>
      <div className="flex flex-col lg:flex-row">
        <div className="hidden lg:block w-64 pt-[16px]">
          <ul className='flex flex-col gap-y-[10px]'>
            {/* Masaüstü Menü */}
            <li className={`p-4 cursor-pointer text-[#71717A] rounded-xl flex justify-between items-center ${activeMenu === 'premium' ? 'bg-gray-100 text-[#09090B]' : ''}`} onClick={() => setActiveMenu('premium')}>
              <span>Premium</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow" />
            </li>
            <li className={`p-4 cursor-pointer text-[#71717A] rounded-xl flex justify-between items-center ${activeMenu === 'aldiklarim' ? 'bg-gray-100 text-[#09090B]' : ''}`} onClick={() => setActiveMenu('aldiklarim')}>
              <span>Aldıklarım</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow" />
            </li>
            <li className={`p-4 cursor-pointer text-[#71717A] rounded-xl flex justify-between items-center ${activeMenu === 'sattiklarim' ? 'bg-gray-100 text-[#09090B]' : ''}`} onClick={() => setActiveMenu('sattiklarim')}>
              <span>Sattıklarım</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow" />
            </li>
            <li className={`p-4 cursor-pointer text-[#71717A] rounded-xl flex justify-between items-center ${activeMenu === 'araciAlacakKisi' ? 'bg-gray-100 text-[#09090B]' : ''}`} onClick={() => setActiveMenu('araciAlacakKisi')}>
              <span>Aracı Alacak Kişi</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow" />
            </li>
            <li className={`p-4 cursor-pointer text-[#71717A] rounded-xl flex justify-between items-center ${activeMenu === 'provizyonBilgilerim' ? 'bg-gray-100 text-[#09090B]' : ''}`} onClick={() => setActiveMenu('provizyonBilgilerim')}>
              <span>Provizyon Bilgilerim</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow" />
            </li>
            <li className={`p-4 cursor-pointer text-[#71717A] rounded-xl flex justify-between items-center ${activeMenu === 'profilBilgilerim' ? 'bg-gray-100 text-[#09090B]' : ''}`} onClick={() => setActiveMenu('profilBilgilerim')}>
              <span>Profil Bilgilerim</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow" />
            </li>
            <li className={`p-4 cursor-pointer text-[#71717A] rounded-xl flex justify-between items-center ${activeMenu === 'iletisimTercihlerim' ? 'bg-gray-100 text-[#09090B]' : ''}`} onClick={() => setActiveMenu('iletisimTercihlerim')}>
              <span>İletişim Tercihlerim</span>
              <img src="/assets/icons/right-arrow.svg" alt="right-arrow" />
            </li>
          </ul>
        </div>

        {/* Mobil Menü için Swiper */}
        <div className="lg:hidden w-full">
          <Swiper
            navigation
            spaceBetween={10}
            slidesPerView={2}
            className="py-4"
          >
            <SwiperSlide onClick={() => setActiveMenu('premium')}>
              <div className={`p-1.5 lg:p-4 cursor-pointer text-[#71717A] rounded-xl text-center ${activeMenu === 'premium' ? 'bg-gray-100 text-[#09090B]' : ''}`}>
                Premium
              </div>
            </SwiperSlide>
            <SwiperSlide onClick={() => setActiveMenu('aldiklarim')}>
              <div className={`p-1.5 lg:p-4 cursor-pointer text-[#71717A] rounded-xl text-center ${activeMenu === 'aldiklarim' ? 'bg-gray-100 text-[#09090B]' : ''}`}>
                Aldıklarım
              </div>
            </SwiperSlide>
            <SwiperSlide onClick={() => setActiveMenu('sattiklarim')}>
              <div className={`p-1.5 lg:p-4 cursor-pointer text-[#71717A] rounded-xl text-center ${activeMenu === 'sattiklarim' ? 'bg-gray-100 text-[#09090B]' : ''}`}>
                Sattıklarım
              </div>
            </SwiperSlide>
            <SwiperSlide onClick={() => setActiveMenu('araciAlacakKisi')}>
              <div className={`p-1.5 lg:p-4 cursor-pointer text-[#71717A] rounded-xl text-center ${activeMenu === 'araciAlacakKisi' ? 'bg-gray-100 text-[#09090B]' : ''}`}>
                Aracı Alacak Kişi
              </div>
            </SwiperSlide>
            <SwiperSlide onClick={() => setActiveMenu('provizyonBilgilerim')}>
              <div className={`p-1.5 lg:p-4 cursor-pointer text-[#71717A] rounded-xl text-center ${activeMenu === 'provizyonBilgilerim' ? 'bg-gray-100 text-[#09090B]' : ''}`}>
                Provizyon Bilgilerim
              </div>
            </SwiperSlide>
            <SwiperSlide onClick={() => setActiveMenu('profilBilgilerim')}>
              <div className={`p-1.5 lg:p-4 cursor-pointer text-[#71717A] rounded-xl text-center ${activeMenu === 'profilBilgilerim' ? 'bg-gray-100 text-[#09090B]' : ''}`}>
                Profil Bilgilerim
              </div>
            </SwiperSlide>
            <SwiperSlide onClick={() => setActiveMenu('iletisimTercihlerim')}>
              <div className={`p-1.5 lg:p-4 cursor-pointer text-[#71717A] rounded-xl text-center ${activeMenu === 'iletisimTercihlerim' ? 'bg-gray-100 text-[#09090B]' : ''}`}>
                İletişim Tercihlerim
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="lg:flex-1 lg:pl-[40px] lg:pt-[16px]">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Profile;
