import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // BrowserRouter'ı içe aktar
import './index.css';
import 'antd/dist/reset.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <Router> 
      <App />
    </Router>
    </PersistGate>
  </Provider>,
);

reportWebVitals();
