import React, { useState } from 'react';
import LoginForm from './general/LoginForm';
import RegisterForm from './general/SignupForm';



const AuthModals = () => {
  

  return (
    <div>
      <div className="w-full flex items-center  gap-x-[16px]">
        <LoginForm />
        <RegisterForm />
      </div>
    </div>
  );
};

export default AuthModals;
