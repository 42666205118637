import React, { useState } from "react";
import { Modal, Button, Input, Form, Steps, message, Checkbox } from "antd";
import { PhoneOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons";
import AxiosController from "../../api/apiController";
import { useDispatch } from "react-redux"; // Redux için
import { loginSuccess } from "../../store/authSlice"; // loginSuccess action'ı
import { Link } from "react-router-dom";

const { Step } = Steps;

const LoginForm = () => {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [accessToken, setAccessToken] = useState(null); // Geçici access_token
  const dispatch = useDispatch(); // Redux dispatch
  const [rememberMe, setRememberMe] = useState(false); // "Beni hatırla" state

  const handleNext = () => setCurrentStep(currentStep + 1);
  const handlePrev = () => setCurrentStep(currentStep - 1);

  const handleLoginOpen = () => {
    setLoginOpen(true);
    setCurrentStep(0); // her login açıldığında step sıfırlansın
  };
  const handleLoginClose = () => setLoginOpen(false);

  // 1. Telefon ve şifre ile giriş API çağrısı
  const handleLoginSubmit = async (values) => {
    try {
      const response = await AxiosController.post("/v1/auth/login", values);
      setAccessToken(response.data.access_token); // API'den gelen geçici access_token
      message.success("OTP gönderildi. Lütfen telefonunuza gelen OTP kodunu girin.");
      handleNext();
    } catch (error) {
      message.error("Giriş başarısız. Lütfen bilgilerinizi kontrol edin.");
    }
  };

  // 2. OTP doğrulama API çağrısı
  const handleOtpValidation = async (values) => {
    try {
      const data = {
        login_token: accessToken, // Geçici access_token
        otp_code: values.otp, // Kullanıcının girdiği OTP
      };
      const response = await AxiosController.post("/v1/auth/verify-otp", data);
      message.success("OTP doğrulandı, giriş yapılıyor.");

      // Giriş başarılı olduğunda Redux'a token'i kaydediyoruz
      dispatch(loginSuccess({ token: response.data.access_token, user: null }));
      setLoginOpen(false); // Başarılıysa modalı kapat
    } catch (error) {
      message.error("OTP doğrulama başarısız. Lütfen tekrar deneyin.");
    }
  };
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };
  return (
    <>
      <button
        onClick={handleLoginOpen}
        className="bg-[#FAFAFA] rounded-[56px] w-[111px] h-[48px] hover:bg-[#FFEB00]"
      >
        Giriş Yap
      </button>

      <Modal
        // title="Giriş Yap"
        open={isLoginOpen}
        onCancel={handleLoginClose}
        footer={null}
        className="special-login"
      >
        {/* <Steps current={currentStep}>
          <Step title="Telefon ve Şifre" />
          <Step title="OTP Doğrulama" />
        </Steps> */}

        <Form
          layout="vertical"
          onFinish={currentStep === 0 ? handleLoginSubmit : handleOtpValidation}
        >
          <div className="flex flex-col md:flex-row items-center"> 
            <div className="w-full md:w-1/2 hidden  md:flex h-full">
              <img src="/assets/img/login-ferrari.png" alt="login" height={'100%'} className="rounded-l-[16px] h-full" />
            </div>
            <div className="flex flex-col w-full md:w-1/2 px-8 py-8 md:py-4">
              {currentStep === 0 && (
                <>
                  <div className="flex flex-col">
                    <span className="text-[#09090B] font-semibold text-[20px]">Giriş Yap</span>
                    <span className="text-[#52525B] text-sm mt-[4px]">
                      Welcome back! Log in to your account.
                    </span>
                  </div>
                  <Form.Item
                    name="phone"
                    label="Telefon"
                    rules={[{ required: true, message: "Lütfen telefon numaranızı girin!" }]}
                    className="mt-[12px]"
                  >
                    <Input
                      prefix={<PhoneOutlined />}
                      placeholder="Telefon"
                      type="number"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Şifre"
                    rules={[{ required: true, message: "Lütfen şifrenizi girin!" }]}
                    className="-mt-2.5"
                  >
                    <Input
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder="Şifre"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                    />
                  </Form.Item>
                  <Form.Item className="mb-[8px] -mt-4 self-end">
                    <Checkbox
                      className="custom-checkbox"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    >
                      Beni Hatırla
                    </Checkbox>
                  </Form.Item>
                  <button
                    className="bg-[#FFEB00] rounded-[12px] text-[#09090B] flex justify-center items-center gap-2 p-[12px] font-medium"
                    type="primary"
                    htmlType="submit"
                  >
                    <img src="/assets/icons/login-icon.svg" alt="login-icon" />
                    <span>Giriş Yap</span>
                  </button>
                  <Link to="/" className="underline text-[#09090B] text-sm self-center mt-3">
                    Şifreni mi unuttun?
                  </Link>
                </>
              )}
              {currentStep === 1 && (<>
                <div className="flex flex-col">
                  <span className="text-[#09090B] font-semibold text-[20px]">OTP Gir</span>
                  <span className="text-[#52525B] text-sm mt-[4px]">
                    Welcome back! Log in to your account.
                  </span>
                </div>
                <Form.Item
                  name="otp"
                  label="OTP"
                  rules={[{ required: true, message: "Lütfen OTP kodunu girin!" }]}
                      className="mt-[12px]"
                >
                  <Input prefix={<KeyOutlined />} placeholder="OTP" className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1" />
                </Form.Item>
                <div className="flex flex-row gap-4">
                  {currentStep > 0 && (
                     <button onClick={handlePrev}
                     className="bg-[#FFEB00] rounded-[12px] text-[#09090B] flex justify-center items-center gap-2 p-[12px] font-medium"
                     type="primary"
                     htmlType="button"
                   >
                    Geri
                   </button>
                  )}
                  <button
                    className="bg-[#FFEB00] rounded-[12px] text-[#09090B] flex justify-center items-center gap-2 p-[12px] font-medium"
                    type="primary"
                    htmlType="submit"
                  >
                    <img src="/assets/icons/login-icon.svg" alt="login-icon" />
                    <span>      OTP Doğrula ve Giriş Yap</span>
                  </button>
                </div>

              </>)}
            </div>
          </div>



        </Form>
      </Modal>
    </>
  );
};

export default LoginForm;
