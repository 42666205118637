import React, { useState } from 'react';
import { Input, Checkbox } from 'antd';

const ContactTab = () => {
  // Checkbox state'lerini yönetmek için
  const [checked, setChecked] = useState({
    call: false,
    sms: false,
    email: false,
    notification: false,
  });

  // Checkbox durumunu değiştirmek için
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setChecked((prevState) => ({ ...prevState, [name]: checked }));
  };

  return (
    <div className="w-[984px] h-[290px] fixed top-[120px] left-[456px] p-[24px_0_0_0] gap-[28px] border rounded-tl-[16px] opacity-0 border-t border-l">
      {/* Başlık */}
      <h1 className="text-left text-lg font-semibold mb-4">İletişim Tercihlerim</h1>

      {/* Inputlar */}
      <div className="flex items-center justify-between mb-[28px]">
        {/* Cep Telefonu */}
        <div className="flex flex-col">
          <label className="mb-1">Cep Telefonu</label>
          <Input placeholder="Cep Telefonu" className="w-[280px]" />
        </div>

        {/* E-posta */}
        <div className="flex flex-col">
          <label className="mb-1">E-posta</label>
          <Input placeholder="E-posta" className="w-[280px]" />
        </div>
      </div>

      {/* Çizgi */}
      <div className="h-px w-full bg-gray-300 my-[28px]" />

      {/* İletişim İzinleri */}
      <h2 className="text-left text-lg font-semibold mb-4">İletişim İzinleri</h2>
      <div className="flex gap-4">
        {/* Sesli Arama */}
        <Checkbox
          name="call"
          checked={checked.call}
          onChange={handleCheckboxChange}
          className={`${checked.call ? 'w-[20px] h-[20px] border rounded-tl-[6px]' : ''}`}
        >
          Sesli Arama
        </Checkbox>

        {/* SMS */}
        <Checkbox
          name="sms"
          checked={checked.sms}
          onChange={handleCheckboxChange}
          className={`${checked.sms ? 'w-[20px] h-[20px] border rounded-tl-[6px]' : ''}`}
        >
          SMS
        </Checkbox>

        {/* E-posta */}
        <Checkbox
          name="email"
          checked={checked.email}
          onChange={handleCheckboxChange}
          className={`${checked.email ? 'w-[20px] h-[20px] border rounded-tl-[6px]' : ''}`}
        >
          E-posta
        </Checkbox>

        {/* Bildirim */}
        <Checkbox
          name="notification"
          checked={checked.notification}
          onChange={handleCheckboxChange}
          className={`${checked.notification ? 'w-[20px] h-[20px] border rounded-tl-[6px]' : ''}`}
        >
          Bildirim
        </Checkbox>
      </div>
    </div>
  );
};

export default ContactTab;
