import React, { useState } from 'react';
import { Table, Button, Space, Modal, Form, Input } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const CarDriverPersonTab = () => {
  const [dataSource, setDataSource] = useState([
    {
      key: '1',
      name: 'Ali Veli',
      tckn: '12345678901',
      phone: '555-555-5555',
    },
    {
      key: '2',
      name: 'Ayşe Fatma',
      tckn: '98765432109',
      phone: '533-333-3333',
    },
    {
      key: '3',
      name: 'Ayşe Fatma',
      tckn: '98765432109',
      phone: '533-333-3333',
    },
    {
      key: '4',
      name: 'Ayşe Fatma',
      tckn: '98765432109',
      phone: '533-333-3333',
    },
    {
      key: '5',
      name: 'Ayşe Fatma',
      tckn: '98765432109',
      phone: '533-333-3333',
    },
    // Daha fazla veri...
  ]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleEdit = (record) => {
    console.log('Edit record:', record);
  };

  const handleDelete = (key) => {
    setDataSource(dataSource.filter(item => item.key !== key));
  };

  const handleAddNewPerson = (values) => {
    const newData = {
      key: (dataSource.length + 1).toString(),
      name: values.name,
      tckn: values.tckn,
      phone: values.phone,
    };
    setDataSource([...dataSource, newData]);
    setIsModalVisible(false);
    form.resetFields();
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'no',
      render: (text, record, index) => index + 1,
      width: '7%', // Kolona genişlik verdik
    },
    {
      title: 'Adı Soyadı',
      dataIndex: 'name',
      key: 'name',
      width: '30%', // Adı Soyadı kolonuna genişlik verdik
    },
    {
      title: 'TCKN',
      dataIndex: 'tckn',
      key: 'tckn',
      width: '30%', // TCKN kolonuna genişlik verdik
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
      width: '30%', // Telefon kolonuna genişlik verdik
    },
    {
      title: 'İşlemler',
      key: 'action',
      align: '',
      render: (text, record) => (
        <Space size="">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          />
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.key)}
          />
        </Space>
      ),
      width: '50%', // İşlemler kolonuna genişlik verdik
    },
  ];

  return (
    <div>
      <div className='bg-white p-[12px] mt-5 lg:mt-0 lg:p-[24px] rounded-[16px] border'>
        {dataSource.length > 0 ?
          <>
            <div className='flex justify-between items-center'>
              <h6 className='text-[#09090B] font-semibold'>Aracı Alacak Kişi</h6>
              <button
                className='bg-[#FFEB00] rounded-[56px] py-[5px] px-[20px] text-sm font-semibold'
                onClick={() => setIsModalVisible(true)}
              >
                Yeni Kişi Ekle
              </button>
            </div>
            <Table
              dataSource={dataSource}
              columns={columns}
              className='mt-[12px] lg:mt-[28px]'
              pagination={false}
              scroll={{ x: true }} // Daha küçük ekranlar için kaydırma eklendi
            />
          </>
          :
          <div className="flex flex-col justify-center items-center min-h-[400px]">
            <img src="/assets/icons/car-solid-standart.svg" alt="car-icon" />
            <span className="mt-[28px] text-[18px] font-medium text-[#09090B]">
              Henüz kimseyi eklemediniz
            </span>
            <span className="mt-[12px] text-sm text-[#71717A]">Lorem ipsum dolor</span>
            <button
              className="bg-[#FFEB00] rounded-[56px] py-[5px] px-[20px] mt-[8px] text-sm font-semibold"
              onClick={() => setIsModalVisible(true)}
            >
              Yeni Kişi Ekle
            </button>
          </div>
        }
        {/* Modal için Kod */}
        <Modal
          // title="Yeni Kişi Ekle"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddNewPerson}
            initialValues={{
              name: '',
              tckn: '',
              phone: '',
            }}
            className='mt-[16px] pt-4 pb-2 px-5'
          >
            <div className='flex flex-col'>
              <div className='font-semibold text-lg mb-5'>Yeni Kişi Ekle</div>
              <div className="flex justify-between gap-[16px]">
                <Form.Item
                  label="Adı"
                  name="name"
                  rules={[
                    { required: true, message: 'Lütfen adınızı ve soyadınızı giriniz!' },
                  ]}
                  className='w-[50%] border-[#E4E4E7] rounded-[12px] '
                >
                  <Input placeholder="Adı" className='p-[10px]' />
                </Form.Item>
                <Form.Item
                  label="Soyadı"
                  name="surNamne"
                  rules={[
                    { required: true, message: 'Lütfen soyadınızı giriniz!' },
                  ]}
                  className='w-[50%] border-[#E4E4E7] rounded-[12px] '
                >
                  <Input placeholder="Adı" className='p-[10px]' />
                </Form.Item>

              </div>
              <div className="flex justify-between -mt-2 gap-[16px]">
                <Form.Item
                  label="TCKN"
                  name="tckn"
                  rules={[
                    { required: true, message: 'Lütfen TCKN giriniz!' },
                    { len: 11, message: 'TCKN 11 karakter olmalıdır!' },
                  ]}
                  className='w-[50%] border-[#E4E4E7] rounded-[12px] '
                >
                  <Input placeholder="TCKN" className='p-[10px]' />
                </Form.Item>
                <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[
                    { required: true, message: 'Lütfen telefon numarası giriniz!' },
                    { pattern: /^[0-9]{10}$/, message: 'Geçerli bir telefon numarası girin (10 haneli)' },
                  ]}
                  className='w-[50%] border-[#E4E4E7] rounded-[12px] '
                >
                  <Input placeholder="Telefon" className='p-[10px]' />
                </Form.Item>
              </div>
            </div>

            <Form.Item>
              <Button htmlType="submit" block className='bg-[#FFEB00] p-[8px] rounded-[12px] h-[56px] text-[15px] text-[#09090B] font-medium border-none hover:text-[#0909090B]'>
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default CarDriverPersonTab;
