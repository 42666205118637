import React from 'react'
import { Link } from 'react-router-dom'
import { cmsImageUrl } from '../../api/apiControllerCms'

const Hizmetler = ({ data }) => {
    return (
        <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-0">
            {data?.services?.map((item, index) => (
                <div key={index} className="flex flex-col items-center">
                    <img
                        src={cmsImageUrl+ item?.icon?.url}
                        alt="car-accept"
                        className="w-[80px] h-[80px]"
                    />
                    <h3 className="mt-[32px] text-[20px] font-medium text-[#09090B]">
                        {item?.title}
                    </h3>
                    <div className="mt-[16px] text-center text-sm text-[#52525B] max-w-[254px]">
                        {item?.desc}
                    </div>
                    <Link
                        to="/"
                        className="flex items-center gap-2 text-sm text-[#09090B] bg-zinc-100 py-[12px] px-[20px] rounded-[40px] mt-[32px] hover:py-[12px] hover:px-[20px] hover:bg-[#FFEB00] hover:rounded-[40px]"
                    >
                        <span>Daha Fazlası</span>
                        <img src="/assets/icons/right-arrow.svg" alt="right-arrow-icon" />
                    </Link>
                </div>
            ))}
            </div>
        </div>
    )
}

export default Hizmetler