import React, { useEffect, useState } from "react";
import AxiosCmsController from "../../api/apiControllerCms";

const About = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosCmsController.get('/api/about-us?populate=image')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching about us data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="container lg:mx-auto">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          {/* Simple loading spinner */}
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col  lg:mx-auto">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-[32px] lg:gap-[64px]">
            <div className="flex flex-col">
              <h2 className="text-[32px] lg:text-[48px] font-medium lg:font-semibold">
                  {data?.PageTitle}
              </h2>
              <article className="font-light text-[#09090B] leading-tight w-[370px] lg:leading-relaxed lg:w-[760px]">
                {data?.PageDescription || 'Content not available at the moment.'}
              </article>
            </div>
            <div className="w-96 lg:w-full">
              <img
                src="/assets/img/aboutcar.png"
                alt="about-img"
                className="rounded-xl"
              />
            </div>
          </div>
          <div className="mt-[36px] lg:mt-[72px] grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:gap-y-0 lg:gap-x-[48px]">
            <div className="flex flex-col">
              <h5 className="text-[#050E25] text-[24px] font-bold">{data?.MisyonTitle}</h5>
              <article className="font-light text-[#27272A] leading-snug max-w-[370px] lg:max-w-[648px]">
                {data?.MisyonDesc || 'Misyon bilgisi mevcut değil.'}
              </article>
            </div>
            <div className="flex flex-col lg:ml-7">
            <h5 className="text-[#050E25] text-[24px] font-bold">{data?.VizyonTitle}</h5>
              <article className="font-light text-[#27272A] leading-snug max-w-[370px] lg:max-w-[648px]">
                {data?.VizyonDesc || 'Vizyon bilgisi mevcut değil.'}
              </article>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
