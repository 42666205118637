import React, { useEffect, useState } from "react";
import AxiosController from "../../api/apiController";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const CarFilter = () => {
  const navigate = useNavigate()
  const [locations, setLocations] = useState(null);
  const [brands, setBrands] = useState(null);
  const [models, setModels] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        // Fetch locations
        const locationResponse = await AxiosController.get("/v1/home/select/location");
        if (locationResponse.data) {
          setLocations(locationResponse.data);
        }

        // Fetch brands
        const brandResponse = await AxiosController.get("/v1/home/select/brand");
        if (brandResponse.data) {
          setBrands(brandResponse.data);
        }

        // Fetch vehicle types
      } catch (error) {
        console.error("Filtre verileri getirilirken hata oluştu:", error);
        message.error("Filtre verileri getirilirken bir hata oluştu.");
      }
    };

    fetchFilters();
  }, []);

  // Fetch models whenever the selected brand changes
  useEffect(() => {
    const fetchModels = async () => {
      if (selectedBrand) {
        try {
          const modelResponse = await AxiosController.get("/v1/home/select/model", {
            params: { brand_id: selectedBrand },
          });
          if (modelResponse.data) {
            setModels(modelResponse.data);
          }
        } catch (error) {
          console.error("Modeller getirilirken hata oluştu:", error);
          message.error("Modeller getirilirken bir hata oluştu.");
        }
      } else {
        setModels([]);
      }
    };

    fetchModels();
  }, [selectedBrand]);

  // Form submit işlemi
  const handleSubmit = (event) => {
    event.preventDefault();
    let newParams = {}
    if (selectedLocation) {
      newParams[locations.key] = selectedLocation
    }
    if (selectedBrand) {
      newParams[brands.key] = selectedBrand
    }
    if (selectedModel) {
      newParams[models.key] = selectedModel
    }
    navigate({
      pathname: "/auctions",
      search: `?${new URLSearchParams(newParams).toString()}`,
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="py-4 flex flex-col lg:flex-row lg:items-center rounded-[24px] gap-4 bg-white lg:w-full justify-between px-4"
    >
      {/* Location Select */}
      <div className="flex items-center gap-1.5 lg:gap-2.5 w-full">
        <img src="/assets/icons/location-mark-icon.svg" alt="location-icon" />
        <div className="w-full">
          <label
            htmlFor="location-select"
            className="block text-[#52525B] mb-1 text-sm font-light"
          >
            Konum / Lokasyon
          </label>
          <select
            id="location-select"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            className="rounded p-2 w-full"
          >
            <option value="">Seçiniz</option>
            {locations?.items?.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Divider */}
      <div className="h-[48px] w-px bg-[#18181C] bg-opacity-20 mt-2.5 hidden lg:block"></div>
      <div className="w-full h-px bg-[#18181C] bg-opacity-20 my-2.5 lg:hidden"></div>

      {/* Brand Select */}
      <div className="flex items-center gap-1.5 lg:gap-2.5 w-full">
        <img src="/assets/icons/car-icon.svg" alt="car-icon" />
        <div className="w-full">
          <label
            htmlFor="brand-select"
            className="block text-[#52525B] mb-1 text-sm font-light"
          >
            Araç Markası
          </label>
          <select
            id="brand-select"
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
            className="rounded p-2 w-full"
          >
            <option value="">Seçiniz</option>
            {brands?.items?.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Divider */}
      <div className="h-[48px] w-px bg-[#18181C] bg-opacity-20 mt-2.5 hidden lg:block"></div>
      <div className="w-full h-px bg-[#18181C] bg-opacity-20 my-2.5 lg:hidden"></div>

      {/* Model Select */}
      <div className="flex items-center gap-1.5 lg:gap-2.5 w-full">
        <img src="/assets/icons/car-icon.svg" alt="car-icon" />
        <div className="w-full">
          <label
            htmlFor="model-select"
            className="block text-[#52525B] mb-1 text-sm font-light"
          >
            Araç Modeli
          </label>
          <select
            id="model-select"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            className="rounded p-2 w-full"
            disabled={!selectedBrand}
          >
            <option value="">Seçiniz</option>
            {models?.items?.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))}
          </select>
        </div>
      </div>




      {/* Submit Button */}
      <div className="min-w-max bg-[#FFEB00] text-black font-medium px-4 py-2 flex justify-center rounded-[12px] items-center gap-1.5 lg:w-[192px] h-[64px]">
        <img src="/assets/icons/search-icon.svg" alt="search-icon" />
        <button type="submit">Hemen Bul</button>
      </div>
    </form>
  );
};

export default CarFilter;
