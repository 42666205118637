// utils.js
export const formatCurrency = (amount) => {
    if (isNaN(amount)) return "Invalid amount";
  
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
    }).format(amount);
  };
  