import React, { useState, useEffect } from "react";
import { Collapse, Checkbox, Row, Col, Input, Spin, Pagination, message, Tag } from "antd";
import "../../css/FilterAccordion.css";
import { Link, useLocation } from "react-router-dom";
import AxiosController, { imageBaseUrl } from "../../api/apiController";

import { InfoOutlined } from '@ant-design/icons';
import { formatCurrency } from "../../utils/FormatCurrency";
const { Panel } = Collapse;

const Auctions = () => {
  const location = useLocation();
  // Filtre Verilerini Tutacak State

  const [activeKey, setActiveKey] = useState([1, 2, 3]);
  const [filterOptions, setFilterOptions] = useState([]);

  // Filtre Seçimleri
  const [selectedFilters, setSelectedFilters] = useState({});

  // Data States
  const [cars, setCars] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filters = {
      city_ids: params.get("city_ids"),
      brand_ids: params.get("brand_ids"),
      series_ids: params.get("series_ids"),
    };
    setSelectedFilters(filters)
  }, [location.search]);

  // Filtre Verilerini Getirme
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await AxiosController.get("/v1/auction/car/filters/");
        if (response.data) {
          setFilterOptions(response.data);
          console.log(response.data);

          // Determine active keys based on non-empty filter values
          const activeKeys = response.data.reduce((acc, item, index) => {
            if (selectedFilters[item.key]) { // Check if the filter's key has a value
              acc.push(index); // Push index to activeKeys if key is non-empty
            }
            return acc;
          }, []);
          setActiveKey(activeKeys); // Update activeKey state with filtered keys
        }
      } catch (error) {
        console.error("Filtre verileri getirilirken hata oluştu:", error);
        message.error("Filtre verileri getirilirken bir hata oluştu.");
      }
    };

    fetchFilters();
  }, [selectedFilters]);


  // Filtre Seçimlerine Göre Açık Artırmaları Getirme
  useEffect(() => {
    const fetchAuctions = async () => {
      setLoading(true);
      try {
        const params = {
          page: currentPage,
          page_size: pageSize,
        };

        Object.keys(selectedFilters).forEach(key => {
          const value = selectedFilters[key];
          if (Array.isArray(value) && value.length > 0) {
            params[key] = value.join(',');
          } else if (value !== '' && value !== undefined) {
            params[key] = value;
          }
        });

        const response = await AxiosController.get("/v1/auction/car/auctions/", { params });

        setCars(response.data);
        setTotalResults(response.total);
      } catch (error) {
        console.error("Error fetching auctions:", error);
        message.error("Açık artırmalar getirilirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchAuctions();
  }, [selectedFilters, currentPage, pageSize]);

  // "Select" Tipindeki Filtreler için Handler
  const handleFilterChange = (filterKey, e) => {
    const { value, checked } = e.target;
    setSelectedFilters(prev => {
      const prevValues = prev[filterKey] || [];
      if (checked) {
        return { ...prev, [filterKey]: [...prevValues, Number(value)] };
      } else {
        return { ...prev, [filterKey]: prevValues.filter(val => val !== Number(value)) };
      }
    });
  };

  // "Range" Tipindeki Filtreler için Handler
  const handleRangeChange = (rangeKey, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [rangeKey]: value ? Number(value) : '',
    }));
  };

  return (
    <div className="container mx-auto">
      <div className="flex">
        {/* Filtreler Bölümü */}
        <div className="hidden lg:flex lg:flex-col lg:w-[20%]">
          <h6>Filtreler</h6>
          {/* <div className="h-px w-full bg-[#18181C] bg-opacity-10 mt-3 mb-5"></div> */}
          <div className="flex flex-col">
            <Collapse defaultActiveKey={[0, 1, 2, 3]} className="custom-collapse">
              {filterOptions.map((filter, index) => (
                <Panel header={<div className="w-full flex flex-row items-center justify-between"> <span>{filter.name} </span> {selectedFilters[filter?.key] ? <Tag color="#FFEB00"><span className="text-black"><InfoOutlined /></span></Tag> : <></>}</div>} key={index} className="custom-panel">
                  <Row className="max-h-44 overflow-y-auto">
                    {filter.type === "select" && filter.items.map(item => (
                      <Col span={24} key={item.id}>
                        <Checkbox
                          value={item.id}
                          onChange={(e) => handleFilterChange(filter.key, e)}
                          checked={selectedFilters[filter.key]?.includes(item.id)}
                        >
                          {item.name}
                        </Checkbox>
                      </Col>
                    ))}

                    {filter.type === "range" && (
                      <Col span={24}>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Input
                              type="number"
                              placeholder={`Min ${filter.name}`}
                              onChange={(e) => handleRangeChange(filter.key.split(",")[0], e.target.value)}
                              value={selectedFilters[filter.key.split(",")[0]] || ''}
                            />
                          </Col>
                          <Col span={12}>
                            <Input
                              type="number"
                              placeholder={`Max ${filter.name}`}
                              onChange={(e) => handleRangeChange(filter.key.split(",")[1], e.target.value)}
                              value={selectedFilters[filter.key.split(",")[1]] || ''}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>

        {/* Açık Artırma Listesi Bölümü */}
        <div className="flex-col lg:w-[80%] lg:ml-[56px]">
          <div className=" lg:mx-0">
            <div className="flex justify-between items-center">
              <span className="text-[#09090B] font-semibold">
                {totalResults} Sonuç Bulundu
              </span>
              <div className="flex items-center gap-2.5">
                {/* Sıralama veya diğer kontroller */}
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center items-center my-10">
                <Spin size="large" />
              </div>
            ) : (
              <>
                {cars.length === 0 ? (
                  <div className="text-center my-10 text-gray-500">
                    Hiç araç bulunamadı.
                  </div>
                ) : (
                  <ul className="mt-[16px] ">
                    {cars.map((car) => (
                      <li key={car.id} className="">
                        <Link to={`/auctions/${car.id}`} className="flex flex-col lg:flex-row gap-[24px] items-center ">
                          <img
                            src={imageBaseUrl + car.car?.images[0]?.img_path}
                            alt={`${car.name} Fotoğraf`}
                            className="w-[380px] h-[218px]"
                          />
                          <div className="w-full">
                            <div className="flex justify-between items-start w-full">
                              <div className="flex flex-col">
                                <h2 className="font-semibold text-[#09090B] text-lg">
                                  {car.title}
                                </h2>
                                <h2 className="mt-[4px] mb-[10px] lg:mt-[8px] lg:mb-[20px] text-sm font-light text-[#09090B]">
                                  {car.car.title}
                                </h2>
                                <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 lg:items-center">
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm text-[#71717A]">
                                      {car.car?.mileage} KM
                                    </span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <img
                                      src={imageBaseUrl + car?.car?.car_fuel_type?.img_path}
                                      alt={`${car.name} Logo`}
                                      className="w-[20px] h-[20px]"
                                    />
                                    <span className="text-sm text-[#71717A]">
                                      {car?.car?.car_fuel_type?.name}
                                    </span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <img
                                      src={imageBaseUrl + car?.car?.car_gear_type?.img_path}
                                      alt={`${car.name} Logo`}
                                      className="w-[20px] h-[20px]"
                                    />
                                    <span className="text-sm text-[#71717A]">
                                      {car?.car?.car_gear_type?.name}
                                    </span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <img
                                      src="/assets/icons/calendar-colourless.svg"
                                      alt="calendar-icon"
                                      className="w-[20px] h-[20px]"
                                    />
                                    <span className="text-sm text-[#71717A]">
                                      {car.car.year} Model
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <img
                                src={imageBaseUrl + car?.car?.car_brand?.img_path}
                                alt={`${car.name} Logo`}
                                className="w-[32px] h-[32px]"
                              />
                            </div>
                            <div className="border-2 dashed-border w-full h-px my-[20px]"></div>
                            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-end">
                              <div className="flex flex-col">
                                <h6 className="text-sm font-light text-[#71717A]">
                                  Açılış Fiyatı
                                </h6>
                                <div className="flex items-center gap-2.5">
                                  <span className="font-bold text-[#09090B] text-[20px]">
                                    {formatCurrency(car.starting_price)}
                                  </span>
                                  <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px] text-[#09090B] font-medium text-sm">
                                    Açık Artırma
                                  </span>
                                </div>
                              </div>
                              <div className="flex items-center gap-2 mt-3 lg:mt-0">
                                <span className="bg-zinc-100 text-center py-[12px] lg:py-[6px] px-[12px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm">
                                  Otomatik Teklif
                                </span>
                                <span className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm">
                                  {formatCurrency(car.min_increment)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="w-full h-px bg-gray-200 my-5"></div>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}

            {/* Pagination Controls */}
            <div className="flex justify-center my-5">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalResults}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auctions;
