import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Steps, Button, Modal, Tooltip, Form, Input, Select, Checkbox } from "antd";
import InputMask from "react-input-mask";
const { Step } = Steps;
const ReceivedDetail = () => {
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalBankVisible, setIsModalBankVisible] = useState(false);
  const [isModalValorVisible, setIsModalValorVisible] = useState(false);
  const [isCreditCardVisible, setIsCreditCardVisible] = useState(false)
  const [installment, setInstallment] = useState(false);
  const [mert, setMert] = useState(null);
  const [cars, setCars] = useState([
    {
      id: 1,
      name: "BMW X5",
      color: "Beyaz",
      image: "lambo",
      initialPrice: "100.000 TL",
      offerPrice: "90.000 TL",
      mileage: "20.000 km",
      fuel: "Benzin",
      transmission: "Otomatik",
      model: "2022",
      logo: "lamborghini-logo",
      carPack: "Sedan 40 TDI Quattro Advanced",
      buyingPrice: "900.000",
    },
    {
      id: 2,
      name: "Mercedes E-Class",
      color: "Siyah",
      image: "merco",
      initialPrice: "120.000 TL",
      offerPrice: "110.000 TL",
      mileage: "15.000 km",
      fuel: "Dizel",
      transmission: "Manuel",
      model: "2021",
      logo: "lamborghini-logo",
      carPack: "Sedan 40 TDI Quattro Advanced",
      buyingPrice: "900.000",
    },
    {
      id: 3,
      name: "Audi Q7",
      color: "Gri",
      image: "grid-car-1",
      initialPrice: "130.000 TL",
      offerPrice: "120.000 TL",
      mileage: "10.000 km",
      fuel: "Hibrit",
      transmission: "Otomatik",
      model: "2023",
      logo: "lamborghini-logo",
      carPack: "Sedan 40 TDI Quattro Advanced",
      buyingPrice: "900.000",
    },
  ]);
  const [current, setCurrent] = useState(0);
  const aliciAdiRef = useRef(null);
  const ibanRef = useRef(null);
  const aciklamaRef = useRef(null);
  const [form] = Form.useForm();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  // Tooltip mesajları için state tanımları
  const [aliciTooltip, setAliciTooltip] = useState("Kopyala");
  const [ibanTooltip, setIbanTooltip] = useState("Kopyala");
  const [aciklamaTooltip, setAciklamaTooltip] = useState("Kopyala");
  const paymentPlans = [
    { installment: "Tek Çekim", monthlyPayment: 0, totalPayment: 500000 },
    { installment: "2 Taksit", monthlyPayment: 250000, totalPayment: 500000 },
    { installment: "3 Taksit", monthlyPayment: 166667, totalPayment: 500000 },
    { installment: "4 Taksit", monthlyPayment: 125000, totalPayment: 500000 },
  ];
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Radio buton seçimini güncelleme fonksiyonu
  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };
  const routeBack = () => {
    window.history.back();
  }
  // Kopyalama işlemi ve Tooltip mesajlarını ayarlama
  const handleCopy = (ref, setTooltip) => {
    if (ref.current) {
      const textToCopy = ref.current.innerText;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setTooltip("Kopyalandı!"); // Tooltip mesajını güncelle

          // 2 saniye sonra Tooltip mesajını tekrar "Kopyala" yap
          setTimeout(() => setTooltip("Kopyala"), 2000);
        })
        .catch((err) => {
          console.error("Kopyalama başarısız:", err);
        });
    }
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    const numericId = parseInt(id, 10);
    setMert(cars.find((car) => car.id == numericId));
  }, []);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showBankModal = () => {
    setIsModalBankVisible(true);
  };
  const showCreditCardModal = () => {
    setIsCreditCardVisible(true);
  }
  const handleCreditCardCancel = () => {
    setIsCreditCardVisible(false);
  };
  const handleBankOk = () => {
    setIsModalBankVisible(false);
  };

  const handleBankCancel = () => {
    setIsModalBankVisible(false);
  };
  const showValorModal = () => {
    setIsModalValorVisible(true);
  };

  const handleVAlorOk = () => {
    setIsModalValorVisible(false);
  };

  const handleValorCancel = () => {
    setIsModalValorVisible(false);
  };
  const onFinish = (values) => {
    console.log("Form values:", values);
    handleOk();
  };

  const onCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };
  const contentList = [
    <div className="">
      <div className="rounded-[16px] border  border-[#F4F4F5] p-[24px] flex flex-col">
        <div className="flex justify-between items-center">
          <div className="text-[#09090B] text-sm">Ödeme İşlemleri</div>
          <div className="rounded-[56px] p-[16px] bg-[#000000] font-semibold text-[#FFEB00] text-xs">
            Ödeme İşlemleri
          </div>
        </div>
        <div className="border-2 dashed-border w-full h-px my-[16px] lg:my-[24px]"></div>
        <div className="flex flex-col">
          <h6 className="text-[#52525B] text-sm ">Size en uygun ödeme yöntemini seçiniz.</h6>
          <div className="grid lg:grid-cols-2 gap-[12px] mt-[16px] lg:mt-[24px]">
            <button onClick={showModal} className="flex justify-between items-center rounded-[12px] border border-[#F4F4F5] py-[16px] px-[16px]">
              <div className="flex items-center gap-2.5">
                <div>
                  <img src="/assets/icons/data-transfer.svg" alt="" />
                </div>
                <div className="font-semibold text-sm">Havale / EFT ile Ödeme</div>
              </div>
              <div>
                <img src="/assets/icons/right-arrow.svg" alt="" />
              </div>
            </button>
            <button onClick={showCreditCardModal} className="flex justify-between items-center rounded-[12px] border border-[#F4F4F5] py-[16px] px-[16px]">
              <div className="flex items-center gap-2.5">
                <div>
                  <img src="/assets/icons/credit-card-stroke.svg" alt="" />
                </div>
                <div className="font-semibold text-sm">Kredi Kartı ile Ödeme</div>
              </div>
              <div>
                <img src="/assets/icons/right-arrow.svg" alt="" />
              </div>
            </button>
            <button onClick={showBankModal} className="flex justify-between items-center rounded-[12px] border border-[#F4F4F5] py-[16px] px-[16px]">
              <div className="flex items-center gap-2.5">
                <div>
                  <img src="/assets/icons/bank-store.svg" alt="" />
                </div>
                <div className="font-semibold text-sm">Bankadan Kredi ile Ödeme</div>
              </div>
              <div>
                <img src="/assets/icons/right-arrow.svg" alt="" />
              </div>
            </button>
            {/* if premiumsa */}
            <button onClick={showValorModal} className="flex justify-between items-center relative rounded-[12px] border border-[#F4F4F5] py-[16px] px-[16px]">
              <div className="flex items-center gap-2.5">
                <div>
                  <img src="/assets/icons/calendar-stroke.svg" alt="" />
                </div>
                <div className="font-semibold text-sm">Valör Desteği ile Ödeme</div>
              </div>
              <div className="flex items-center gap-2.5">
                <div className="bg-[#FFEB00] lg:px-[10px] rounded-[32px] py-[5px] font-semibold text-sm text-[#000000] hidden lg:flex">
                  Size Özel
                </div>
                <div className="bg-[#FFEB00] py-1.5 font-medium rounded-lg px-1.5 text-xs text-[#000000] absolute right-2 -top-3 lg:hidden">
                  Size Özel
                </div>
                <img src="/assets/icons/right-arrow.svg" alt="" />
              </div>
            </button>
          </div>
          <Modal
            title="Havale / EFT ile Ödeme"
            open={isModalVisible}
            footer={null}
            onCancel={handleCancel}
            className="custom-modal"
          >
            <div className="flex flex-col">
              <div className="flex justify-between items-center px-5">
                <div className="flex flex-col">
                  <span className="text-sm text-[#52525B]">Alıcı Adı</span>
                  <span ref={aliciAdiRef} className="font-bold underline">Alıcı Adı Burada Yazacak</span>
                </div>
                <Tooltip title={aliciTooltip} placement="top">
                  <button onClick={() => handleCopy(aliciAdiRef, setAliciTooltip)}>
                    <img src="/assets/icons/copy-stroke.svg" alt="copy-clipboard-icon" />
                  </button>
                </Tooltip>
              </div>
              <div className="w-[576] h-px bg-[#E4E4E7] mx-5 my-[16px]"></div>
              <div className="flex justify-between items-center px-5">
                <div className="flex flex-col">
                  <span className="text-sm text-[#52525B]">IBAN</span>
                  <span ref={ibanRef} className="font-bold underline">TR00 0000 0000 0000 0000 0000 00</span>
                </div>
                <Tooltip title={ibanTooltip} placement="top">
                  <button onClick={() => handleCopy(ibanRef, setIbanTooltip)}>
                    <img src="/assets/icons/copy-stroke.svg" alt="copy-clipboard-icon" />
                  </button>
                </Tooltip>
              </div>
              <div className="w-[576] h-px bg-[#E4E4E7] mx-5 my-[16px]"></div>
              <div className="flex justify-between items-center px-5">
                <div className="flex flex-col">
                  <span className="text-sm text-[#52525B]">Açıklama</span>
                  <span ref={aciklamaRef} className="font-bold underline">X Tarihinde Alınan Y Plakalı Aracın Ödemesi</span>
                </div>
                <Tooltip title={aciklamaTooltip} placement="top">
                  <button onClick={() => handleCopy(aciklamaRef, setAciklamaTooltip)}>
                    <img src="/assets/icons/copy-stroke.svg" alt="copy-clipboard-icon" />
                  </button>
                </Tooltip>
              </div>
              <div className="w-[576] h-px bg-[#E4E4E7] mx-5 my-[16px]"></div>
              <div className="flex justify-between items-center px-5">
                <div className="flex flex-col">
                  <span className="text-sm text-[#52525B]">Tutar</span>
                  <span className="font-bold underline">₺900.000,00</span>
                </div>
              </div>
              <div className="self-end">
                <div className="flex items-center gap-2.5 p-5">
                  <Tooltip title="Havale / EFT Yaptıysanız Tıklayınız" placement="top">
                    <button onClick={handleOk} className="bg-[#FFEB00] font-semibold text-sm text-[#000000] px-4 py-2 rounded-lg hover:bg-yellow-400">Ödemeyi Yaptım</button>
                    {/* <button onClick={handleCancel}>CANCEL</button> */}
                  </Tooltip>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            title="Bankadan Kredi ile Ödeme"
            open={isModalBankVisible}
            footer={null}
            onCancel={handleBankCancel}
            className="custom-modal"
          >
            <Form
              form={form}
              name="paymentForm"
              layout="vertical"
              onFinish={onFinish}
              className="mx-5"
              initialValues={{
                il: "İl Seçiniz",
                ilce: "İlçe Seçiniz",
              }}
            >
              <Form.Item
                label="Adınız"
                name="ad"
                className=""
                rules={[
                  { required: true, message: "Lütfen adınızı giriniz!" },
                  { min: 2, message: "Adınız en az 2 karakter olmalıdır." },
                ]}
              >
                <Input placeholder="Adınızı giriniz" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <Form.Item
                label="Soyadınız"
                name="soyad"
                className="-mt-3"
                rules={[
                  { required: true, message: "Lütfen soyadınızı giriniz!" },
                  { min: 2, message: "Soyadınız en az 2 karakter olmalıdır." },
                ]}
              >
                <Input placeholder="Soyadınızı giriniz" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <Form.Item
                label="T.C.K.N"
                name="tckn"
                className="-mt-3"
                rules={[
                  { required: true, message: "Lütfen T.C.K.N giriniz!" },
                  { len: 11, message: "T.C.K.N 11 karakter olmalıdır." },
                ]}
              >
                <Input placeholder="T.C.K.N giriniz" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <Form.Item
                label="Telefon"
                name="telefon"
                className="-mt-3"
                rules={[
                  { required: true, message: "Lütfen telefon numaranızı giriniz!" },
                  { pattern: /^\d{10}$/, message: "Lütfen geçerli bir telefon numarası giriniz!" },
                ]}
              >
                <Input placeholder="Telefon numaranızı giriniz" type="number" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <Form.Item
                label="Aylık Toplam Gelir Bilginiz"
                name="gelir"
                className="-mt-3"
                rules={[{ required: true, message: "Lütfen aylık gelir bilginizi giriniz!" }]}
              >
                <Input placeholder="Aylık gelir bilginizi giriniz" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <Form.Item
                label="İş Yeri Adı"
                name="isyeriAdi"
                className="-mt-3"
                rules={[{ required: true, message: "Lütfen iş yeri adınızı giriniz!" }]}
              >
                <Input placeholder="İş yeri adınızı giriniz" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <div className="flex gap-4">
                {/* İş Yeri İl */}
                <Form.Item
                  label="İş Yeri İl"
                  name="il"
                  className="-mt-2 w-1/2"
                  rules={[{ required: true, message: "Lütfen iş yeri ilini seçiniz!" }]}
                >
                  <Select placeholder="İl Seçiniz" className="-mt-1 h-10">
                    <Select.Option value="İstanbul">İstanbul</Select.Option>
                    <Select.Option value="Ankara">Ankara</Select.Option>
                    <Select.Option value="İzmir">İzmir</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="İş Yeri İlçe"
                  name="ilce"
                  className="-mt-2 w-1/2 "
                  rules={[{ required: true, message: "Lütfen iş yeri ilçesini seçiniz!" }]}
                >
                  <Select placeholder="İlçe Seçiniz" className="-mt-1 h-10">
                    <Select.Option value="Kadıköy">Kadıköy</Select.Option>
                    <Select.Option value="Çankaya">Çankaya</Select.Option>
                    <Select.Option value="Karşıyaka">Karşıyaka</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item name="kvkk" valuePropName="checked" className="-mt-3">
                <Checkbox onChange={onCheckboxChange}>
                  Verilerimin KVKK kapsamında işlenmesine izin veriyorum.
                </Checkbox>
              </Form.Item>

              {/* Gönder Butonu */}
              <Form.Item className="-mt-3">
                <Tooltip title={isCheckboxChecked ? "" : "Lütfen KVKK onayı veriniz"}>
                  <button
                    type="primary"
                    htmlType="submit"
                    className="w-full mb-5 py-2 rounded-[8px] font-semibold"
                    style={{
                      backgroundColor: isCheckboxChecked ? "#FFEB00" : "#f0f0f0",
                      color: isCheckboxChecked ? "#000000" : "#cccccc",
                    }}
                    disabled={!isCheckboxChecked}
                  >
                    Gönder
                  </button>
                </Tooltip>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Kredi Kartı ile Ödeme"
            open={isCreditCardVisible}
            footer={null}
            onCancel={handleCreditCardCancel}
            className="custom-modal"
          >
            <Form
              form={form}
              name="paymentForm"
              layout="vertical"
              onFinish={onFinish}
              className="mx-5"
            // initialValues={{
            //   il: "İl Seçiniz",
            //   ilce: "İlçe Seçiniz",
            // }}
            >
              {/* Telefon */}

              {/* Aylık Toplam Gelir Bilginiz */}
              <Form.Item
                label="Kart Sahibi"
                name="name"
                className=""
                rules={[{ required: true, message: "Lütfen Kart Sahibi Adını Soyadını Giriniz!" }]}
              >
                <Input placeholder="Kart Sahibi" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <Form.Item
                label="Kart Numarası"
                name="cardNo"
                className="-mt-3"
                rules={[
                  { required: true, message: "Lütfen Kart Numarası Giriniz" },
                  { pattern: /^\d{4} \d{4} \d{4} \d{4}$/, message: "Lütfen Geçerli Bir Kart Numarası Giriniz!" },
                ]}
              >
                <InputMask
                  mask="9999 9999 9999 9999"
                  placeholder="Kart Numarası Giriniz"
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type="text"
                      className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]"
                    />
                  )}
                </InputMask>
              </Form.Item>
              <div className="flex gap-4">
                <Form.Item
                  label="Son Kullanma Tarihi"
                  name="expiryDate"
                  className="-mt-1 w-1/2"
                  rules={[
                    { required: true, message: "Lütfen Son Kullanma Tarihini Giriniz" },
                    { pattern: /^(0[1-9]|1[0-2])\/\d{2}$/, message: "Lütfen Geçerli Bir Tarih Giriniz (MM/YY)!" },
                  ]}
                >
                  <InputMask
                    mask="99/99"
                    placeholder="MM/YY"
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]"
                      />
                    )}
                  </InputMask>
                </Form.Item>
                <Form.Item
                  label="CVV/CVC"
                  name="cvc"
                  className="-mt-1 w-1/2"
                  rules={[{ required: true, message: "Lütfen CVC Giriniz!" }]}
                >
                  <Input placeholder="CVC" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
                </Form.Item>
              </div>
              {installment ?
                <div className="">
                  <h5 className="text-center mb-3 text-lg font-medium">Taksit Seçenekleri</h5>
                  {/* Başlıklar */}
                  <div className="grid grid-cols-4 text-center font-semibold text-gray-700 border-b pb-4 mb-4">
                    <div>Seç</div>
                    <div>Taksit Sayısı</div>
                    <div>Aylık Ödeme</div>
                    <div>Toplam Ödeme</div>
                  </div>

                  {/* Dinamik ve Tek Seçimlik Ödeme Planı Satırları */}
                  <div className="space-y-3">
                    {paymentPlans.map((plan, index) => (
                      <div key={index} className="grid grid-cols-4 text-center py-2 border-b items-center">
                        {/* Radio Button */}
                        <input
                          type="radio"
                          name="paymentPlan"
                          checked={selectedPlan === plan}
                          onChange={() => handleSelectPlan(plan)}
                          className="h-4 w-4 mx-auto"
                        />
                        <div>{plan.installment}</div>
                        <div>{plan.monthlyPayment.toLocaleString()} TL</div>
                        <div>{plan.totalPayment.toLocaleString()} TL</div>
                      </div>
                    ))}
                  </div>

                  {/* Seçilen Planı Göster */}
                  <div className="mt-4">
                    <strong>Seçilen Taksit:</strong>
                    <p className="text-gray-600 mt-2">
                      {selectedPlan ? selectedPlan.installment : "Henüz bir taksit seçilmedi"}
                    </p>
                  </div>
                </div>
                : ''}

              {/* KVKK Checkbox */}
              <Form.Item name="kvkk" valuePropName="checked" className="-mt-1">
                <Checkbox onChange={onCheckboxChange}>
                  Bilgilerimin doğruluğunu onaylıyorum.
                </Checkbox>
              </Form.Item>

              {/* Gönder Butonu */}
              <Form.Item className="-mt-3">
                <Tooltip title={isCheckboxChecked ? "" : "Lütfen KVKK onayı veriniz"}>
                  <button
                    type="primary"
                    htmlType="submit"
                    className="w-full mb-5 py-2 rounded-[8px] font-semibold"
                    style={{
                      backgroundColor: isCheckboxChecked ? "#FFEB00" : "#f0f0f0",
                      color: isCheckboxChecked ? "#000000" : "#cccccc",
                    }}
                    disabled={!isCheckboxChecked} // Checkbox işaretli değilse buton disabled olacak
                  >
                    Gönder
                  </button>
                </Tooltip>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Valör Desteği İle Ödeme"
            open={isModalValorVisible}
            footer={null}
            onCancel={handleValorCancel}
            className="custom-modal"
          >
            <Form
              form={form}
              name="paymentForm"
              layout="vertical"
              onFinish={onFinish}
              className="mx-5"
            // initialValues={{
            //   il: "İl Seçiniz",
            //   ilce: "İlçe Seçiniz",
            // }}
            >
              <Form.Item
                label="Kart Sahibi"
                name="name"
                className=""
                rules={[{ required: true, message: "Lütfen Kart Sahibi Adını Soyadını Giriniz!" }]}
              >
                <Input placeholder="Kart Sahibi" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              </Form.Item>
              <Form.Item
                label="Kart Numarası"
                name="cardNo"
                className="-mt-3"
                rules={[
                  { required: true, message: "Lütfen Kart Numarası Giriniz" },
                  { pattern: /^\d{4} \d{4} \d{4} \d{4}$/, message: "Lütfen Geçerli Bir Kart Numarası Giriniz!" },
                ]}
              >
                <InputMask
                  mask="9999 9999 9999 9999"
                  placeholder="Kart Numarası Giriniz"
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type="text"
                      className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]"
                    />
                  )}
                </InputMask>
              </Form.Item>
              <div className="flex gap-4">
                <Form.Item
                  label="Son Kullanma Tarihi"
                  name="expiryDate"
                  className="-mt-1 w-1/2"
                  rules={[
                    { required: true, message: "Lütfen Son Kullanma Tarihini Giriniz" },
                    { pattern: /^(0[1-9]|1[0-2])\/\d{2}$/, message: "Lütfen Geçerli Bir Tarih Giriniz (MM/YY)!" },
                  ]}
                >
                  <InputMask
                    mask="99/99"
                    placeholder="MM/YY"
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]"
                      />
                    )}
                  </InputMask>
                </Form.Item>
                <Form.Item
                  label="CVV/CVC"
                  name="cvc"
                  className="-mt-1 w-1/2"
                  rules={[{ required: true, message: "Lütfen CVC Giriniz!" }]}
                >
                  <Input placeholder="CVC" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
                </Form.Item>
              </div>
              {installment ?
                <div className="">
                  <h5 className="text-center mb-3 text-lg font-medium">Taksit Seçenekleri</h5>
                  <div className="grid grid-cols-4 text-center font-semibold text-gray-700 border-b pb-4 mb-4">
                    <div>Seç</div>
                    <div>Taksit Sayısı</div>
                    <div>Aylık Ödeme</div>
                    <div>Toplam Ödeme</div>
                  </div>
                  <div className="space-y-3">
                    {paymentPlans.map((plan, index) => (
                      <div key={index} className="grid grid-cols-4 text-center py-2 border-b items-center">
                        <input
                          type="radio"
                          name="paymentPlan"
                          checked={selectedPlan === plan}
                          onChange={() => handleSelectPlan(plan)}
                          className="h-4 w-4 mx-auto"
                        />
                        <div>{plan.installment}</div>
                        <div>{plan.monthlyPayment.toLocaleString()} TL</div>
                        <div>{plan.totalPayment.toLocaleString()} TL</div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4">
                    <strong>Seçilen Taksit:</strong>
                    <p className="text-gray-600 mt-2">
                      {selectedPlan ? selectedPlan.installment : "Henüz bir taksit seçilmedi"}
                    </p>
                  </div>
                </div>
                : ''}

              <Form.Item name="kvkk" valuePropName="checked" className="-mt-1">
                <Checkbox onChange={onCheckboxChange}>
                  Bilgilerimin doğruluğunu onaylıyorum.
                </Checkbox>
              </Form.Item>

              <Form.Item className="-mt-3">
                <Tooltip title={isCheckboxChecked ? "" : "Lütfen KVKK onayı veriniz"}>
                  <button
                    type="primary"
                    htmlType="submit"
                    className="w-full mb-5 py-2 rounded-[8px] font-semibold"
                    style={{
                      backgroundColor: isCheckboxChecked ? "#FFEB00" : "#f0f0f0",
                      color: isCheckboxChecked ? "#000000" : "#cccccc",
                    }}
                    disabled={!isCheckboxChecked}
                  >
                    Gönder
                  </button>
                </Tooltip>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="rounded-[16px] border  border-[#F4F4F5] p-[12px] lg:p-[24px] flex flex-col mt-[16px] lg:mt-[32px]">
        <div className="text-[#09090B] text-sm">Ödeme İşlemleri</div>
        <div className="border-2 dashed-border w-full h-px my-[12px] lg:my-[24px]"></div>
        <div className="flex flex-col gap-y-2 lg:gap-y-0 lg:flex-row justify-between lg:items-center">
          <div className="flex flex-col">
            <h6 className="text-[#52525B] text-sm">Şirket Unvanı</h6>
            <span className="text-[#09090B] font-medium lg:text-base lg:font-semibold">Lorem</span>
          </div>
          <div className="flex flex-col">
            <h6 className="text-[#52525B] text-sm">Vergi Numarası</h6>
            <span className="text-[#09090B] font-medium lg:text-base lg:font-semibold">12345678953543</span>
          </div>
          <div className="flex flex-col">
            <h6 className="text-[#52525B] text-sm">Vergi Dairesi</h6>
            <span className="text-[#09090B] font-medium lg:text-base lg:font-semibold">Büyük Mükellefler V.D.</span>
          </div>
        </div>
      </div>
    </div>,
    <div>
      <h2>İkinci Adım İçeriği</h2>
      <p>Adım 2: Bilgilerinizi doğrulayın.</p>
      <Button type="primary">Doğrula</Button>
    </div>,
    <div>
      <h2>Son Adım İçeriği</h2>
      <p>Adım 3: İşlemi tamamlayın.</p>
      <button onClick={() => alert("Tamamlandı!")}>Tamamla</button>
    </div>,
    // <div>
    //   <h2>Son Adım İçeriği</h2>
    //   <p>Adım 3: İşlemi tamamlayın.</p>
    //   <button onClick={() => alert("Tamamlandı!")}>Tamamla</button>
    // </div>,
  ];
  return (
    <div className="container mx-auto">
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-[22%] lg:mr-[40px] ">
          {/* Aldıklarım tab ına gıtmeli */}
          <button onClick={routeBack} className="lg:hidden mb-1.5">
            <img src="/assets/icons/right-arrow.svg" alt="" className="rotate-180 rounded-full py-1.5 px-2 bg-yellow-200" />
          </button>
          <div className="flex flex-col">
            <img
              src={`/assets/img/${mert?.image}.png`}
              alt={`${mert?.name} Fotoğraf`}
              className="w-[380px] h-[218px]"
            />
            <div className="flex flex-col mt-5">
              <h2 className="font-semibold text-[#09090B] text-lg">{mert?.name}</h2>
              <h2 className="-mt-2 text-sm font-light text-[#09090B]">{mert?.carPack}</h2>
              <button className="bg-[#FFEB00] mt-[12px] lg:mt-[24px] px-[24px] rounded-[56px] py-[16px] font-semibold text-sm text-[#000000]">
                Ekspertiz Raporu
              </button>
              <div className="border-2 dashed-border w-full h-px my-[24px]"></div>
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <h6 className="text-[#71717A] text-sm font-medium">Araç Satış Bedeli</h6>
                  <span className="text-[#71717A] text-sm font-medium">550,00 TL</span>
                </div>
                <div className="flex justify-between">
                  <h6 className="text-[#71717A] text-sm font-medium">Noter Hizmet Bedeli</h6>
                  <span className="text-[#71717A] text-sm font-medium">49,99 TL</span>
                </div>
                <div className="flex justify-between">
                  <h6 className="text-[#71717A] text-sm font-medium">Komisyon Oranı</h6>
                  <span className="text-[#09090B] text-sm font-semibold">% 2.5</span>
                </div>
              </div>
              <div className="border-2 dashed-border w-full h-px my-[24px]"></div>
              <div className="self-end">
                <div className="flex flex-col items-end">
                  <span className="text-xs font-medium text-[#71717A]">Toplam Tutar</span>
                  <span className="text-right text-[20px] text-[#09090B] font-bold">
                    900.000 TL
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[78%]">
          <div className="container mx-auto">
            <Steps current={current} style={{ marginBottom: "0px" }}>
              <Step title="Tescil & Ödeme" />
              <Step title="Teslimat" />
            </Steps>
            <div
              className="lg:content lg:p-[20px] lg:w-[100%] lg:border lg:mt-5"
            >
              {contentList[current]}
            </div>
            {/* <div style={{ marginTop: "24px" }}>
              {current < contentList.length - 1 && (
                <Button type="primary" onClick={next}>
                  İleri
                </Button>
              )}
              {current > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={prev}>
                  Geri
                </Button>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivedDetail;
