import React from 'react'
import { cmsImageUrl } from '../../api/apiControllerCms'

const Tanitim = ({ data }) => {
    return (
        <div className="">
            <div className="flex flex-col lg:flex-row justify-between">
                <div className="lg:hidden">
                    <img
                        src="/assets/img/car-section-image.png"
                        alt="car-location-image"
                    />
                </div>
                <div className="flex flex-col mt-5 lg:mt-0">
                    <span className="font-semibold text-[24px] text-[#FFEB00]  lg:text-start">
                        #1
                    </span>
                    <h3 className="text-[#09090B] text-center lg:text-start font-semibold text-[24px] lg:text-[48px] max-w-[688px]">
                        {data?.title}
                    </h3>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-[16px] lg:gap-[32px] text-[#09090B]">
                        {data?.promotions?.map((item, index) => (
                            <div key={index} className="flex items-center gap-x-[24px]">
                                <div className='bg-[#FFEB00] h-[64px] w-[64px] flex items-center justify-center rounded-[16px]'>
                                    <img
                                        src={cmsImageUrl + item?.icon?.url}
                                        alt="first-grid-icon"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <h5 className="font-medium">{item?.title}</h5>
                                    <span className="text-xs text-[#52525B]">
                                        {item?.desc}
                                    </span>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <div className="hidden lg:block">
                    <img
                        src={cmsImageUrl + data?.image?.url}
                        alt="car-location-image"
                    />
                </div>
            </div>
        </div>
    )
}

export default Tanitim