import React, { useState } from 'react'
import { cmsImageUrl } from '../../api/apiControllerCms';
import CarFilter from '../../components/Home/CarFilter';

const Filtrevebanner = ({ data }) => {
    return (
        <div
            className="relative  bg-[#FFEB00] py-6 lg:py-8 rounded-[24px] bg-cover bg-center overflow-hidden"
            style={{ backgroundImage: `url(${cmsImageUrl + data?.backgroundImage?.url})` }}
        >
            <div className="flex flex-col-reverse md:flex-row py-4 items-center gap-4 px-[16px] md:pr-0 md:pl-[32px] lg:pl-[64px]">
                <div className="w-full md:w-1/2">
                    <span className="font-bold text-[32px] leading-normal lg:text-[64px]">
                        {data?.title}
                    </span>
                </div>
                <div className="w-full md:w-1/2">
                    <img
                        className="relative md:right-[-80px]"
                        src={cmsImageUrl + data?.image?.url}
                        alt="Decorative"
                    />
                </div>
            </div>
            <div className=' px-[16px]  md:px-[32px] lg:px-[64px]'>
                <div className="bg-white rounded-[24px]">
                    <CarFilter />
                </div>
            </div>
        </div>
    )
}

export default Filtrevebanner