import React from 'react';
import { cmsImageUrl } from '../../api/apiControllerCms';

const Adimlar = ({ data }) => {
    console.log(data?.steps)
    return (
        <div className="mt-[60px] lg:mt-[120px] 2xl:w-[1444px]">
            <div className="flex flex-col lg:flex-row justify-between gap-8 items-center">
                {data?.steps?.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className="flex flex-col items-center text-center gap-4">
                            <div className='bg-[#FFEB00] h-[64px] w-[64px] flex items-center justify-center rounded-[16px]'>
                               {item?.icon && <img
                                    src={cmsImageUrl +  item?.icon[0]?.url}
                                    alt={`step-icon-${index}`}
                                />}
                            </div>
                            <div>
                                <h6 className="font-semibold text-[#09090B] mb-[4px] lg:mb-[8px]" style={{ lineHeight: "24px" }}>
                                    {item?.title || "Lokasyonu Seç"}
                                </h6>
                                <span className="text-sm text-[#52525B]" style={{ lineHeight: "21px" }}>
                                    {item?.description || "Aracı satın aldıktan sonra lokasyonu belirleyin"}
                                </span>
                            </div>
                        </div>
                        
                        {index < data?.steps?.length - 1 && (
                            <img
                                src="/assets/icons/right-arrow-dashed.svg"
                                alt="r-arrow-dashed"
                                className="hidden lg:block"
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default Adimlar;
