import React from 'react'
import { Link } from 'react-router-dom'
import { cmsImageUrl } from '../../api/apiControllerCms'
import moment from 'moment'
import 'moment/locale/tr';
moment.locale('tr');


const BlogBolum = ({ data }) => {
    console.log(data)
    return (
        <div className="mt-[60px] lg:mt-[120px]">
            <div className="flex flex-col gap-[20px]">
                <div className="flex justify-between items-center">
                    <h4 className="font-semibold text-[32px]">
                        {data?.title}
                    </h4>
                    <Link
                        to="/blog"
                        className="flex items-center gap-2 -mt-2.5 lg:-mt-0 text-sm text-[#09090B] bg-zinc-100 py-[12px] px-[20px] rounded-[40px] hover:py-[12px] hover:px-[20px] hover:bg-[#FFEB00] hover:rounded-[40px]"
                    >
                        <span>{data?.buttonTitle}</span>
                        <img src="/assets/icons/right-arrow.svg" alt="right-arrow-icon" />
                    </Link>
                </div>
                <div className="">
                    <div className="grid lg:grid-cols-3 gap-y-12 lg:gap-y-0 lg:gap-x-[32px]">
                        {data?.blogs?.map((item, index) => (
                            <Link to={'/blog/' + item?.slug} key={index} className="flex flex-col">
                                <img src={cmsImageUrl + item?.image?.url} alt="blog-img" className='rounded-[16px]  h-[240px] w-full object-cover' />
                                <div className="mt-[24px]">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src="/assets/icons/calendar-colourless.svg"
                                            alt="calender-icon"
                                        />
                                        <span className="text-xs text-[#52525B]">{moment(item?.publishedAt).format('D MMMM YYYY, HH:mm')}</span>
                                    </div>
                                    <h6 className="text-[#1E1E1E] font-medium my-[16px] line-clamp-2">
                                        {item?.title}
                                    </h6>
                                    <span className=" text-sm text-[#52525B] line-clamp-2">
                                        {item?.shortDesc}
                                    </span>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogBolum