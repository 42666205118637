import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthModals from "./AuthModals";
import useAuth from "../hooks/useAuth";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons"; // Ant Design icon for hamburger menu

const Navbar = () => {
  const { isAuthenticated, user } = useAuth();
  const dispatch = useDispatch();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <div className="container mx-auto px-3 md:px-0">
        {/* Desktop Navbar */}
        <nav className="hidden md:flex justify-between py-4">
          <div className="flex items-center gap-[82px]">
            <Link to="/">
              <img src="/assets/icons/tt-logo.svg" alt="tt-logo" />
            </Link>
            <div className="flex items-center gap-x-[48px] text-sm font-medium text-[#09090B]">
              <Link to="/">Anasayfa</Link>
              <Link to="/auctions">Araç Al</Link>
              {/* <Link to="/profile">Profil</Link> */}
              <Link to="/about">Hakkımızda</Link>
              {isAuthenticated && <Link to="/profile">Profilim</Link>}
              <Link to="/contact">İletişim</Link>
            </div>
          </div>
          {isAuthenticated ? (
            <div className="flex items-center gap-x-4">
              <span>{user?.full_name}</span>
              <Button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded">
                Çıkış Yap
              </Button>
            </div>
          ) : (
            <AuthModals />
          )}
        </nav>

        {/* Mobile Navbar */}
        <nav className="flex md:hidden justify-between items-center container mx-auto px-3 py-4">
          <Link to="/">
            <img src="/assets/icons/tt-logo.svg" alt="tt-logo" className="h-10" />
          </Link>
          <button onClick={toggleMobileMenu} className="text-2xl">
            <img src="assets/icons/menu.svg" />
          </button>
        </nav>

        {/* Mobile Menu Dropdown */}
        {isMobileMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 w-full bg-white shadow-lg p-4 px-6 z-10">
            <div className="flex flex-col items-start gap-4 text-sm font-medium text-[#09090B]">
              <Link to="/" onClick={toggleMobileMenu}>Anasayfa</Link>
              <Link to="/auctions" onClick={toggleMobileMenu}>Araç Al</Link>
              <Link to="/about" onClick={toggleMobileMenu}>Hakkımızda</Link>
              {isAuthenticated && <Link to="/profile" onClick={toggleMobileMenu}>Profilim</Link>}
              <Link to="/contact" onClick={toggleMobileMenu}>İletişim</Link>
              {isAuthenticated ? (
                <div className="flex flex-col items-start gap-2">
                  <span>{user?.full_name}</span>
                  <Button onClick={() => { handleLogout(); toggleMobileMenu(); }} className="bg-red-500 text-white px-4 py-2 rounded">
                    Çıkış Yap
                  </Button>
                </div>
              ) : (
                <AuthModals />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="h-px w-full bg-gray-200 hidden lg:block"></div>
    </>
  );
};

export default Navbar;
