import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosCmsController, { cmsImageUrl } from "../../api/apiControllerCms";
import moment from 'moment'
import 'moment/locale/tr';
moment.locale('tr');

const BlogDetail = () => {
  const { slug } = useParams();
  const [blogDetail, setBlogDetail] = useState({});
  const [readingTime, setReadingTime] = useState("");

  const calculateReadingTime = (text) => {
    const words = text ? text.split(/\s+/).length : 0;
    const minutes = Math.ceil(words / 200);
    return `${minutes} dakika okuma`;
  };


  const fetchBlogDetail = async () => {
    try {
      const response = await AxiosCmsController.get(`/api/blogs?filters[slug][$eq]=${slug}&populate=*`);
      setBlogDetail(response.data[0]);
      if (response.data[0]?.content) {
        setReadingTime(calculateReadingTime(response.data[0]?.content));
      }
    } catch (error) {
      console.error("Error fetching blog details:", error);
    }
  };

  useEffect(() => {
    fetchBlogDetail();
  }, [slug]);

  return (
    <div className=" lg:container lg:mx-auto">
      <div className="flex flex-col gap-8">
        <div>
          <h6 className="font-bold text-[24px] lg:text-[32px] text-[#18181C]">
            {blogDetail?.title}
          </h6>
          <div className="flex flex-row items-center gap-4">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/time.svg" alt="time" />
              <span className="text-[#71717A] text-sm text-opacity-60">
                {moment(blogDetail?.publishedAt).format('D MMMM YYYY, HH:mm')}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/readtime.svg" alt="readtime" />
              <span className="text-[#71717A] text-sm text-opacity-60">
               {readingTime}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col  gap-[32px]">
          <div className="w-full ">
            <img
              src={cmsImageUrl + blogDetail?.image?.url}
              height={300}
              alt="blog-car"
              className="rounded-[32px] w-full h-[400px] object-top object-cover"
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: blogDetail?.content }}>
          </div>
        </div>

      </div>
    </div >
  );
};

export default BlogDetail;
