import React, { useState } from "react";
import { Form, Input, Button, DatePicker } from "antd";
const { TextArea } = Input; // TextArea bileşeni Input'tan alınır.
const MyProfileTab = () => {
  const [activeTab, setActiveTab] = useState("Kurumsal");
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Form Values:", values);
  };

  const tcknValidator = (_, value) => {
    if (!value || (value.length === 11 && /^[0-9]+$/.test(value))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("TCKN 11 haneli olmalıdır ve sadece rakam içermelidir!"));
  };

  return (
    <div className="border border-[#F4F4F5] rounded-[16px] p-[12px] mt-5 lg:mt-0 lg:p-[24px]">
      <div className="flex items-center gap-2.5">
        <button
          onClick={() => setActiveTab("Kurumsal")}
          className={`p-2 rounded text-sm font-semibold transition-colors duration-300 ${activeTab === "Kurumsal"
              ? "bg-[#FFEB00] text-zinc-900"
              : "text-[#FFEB00] hover:bg-[#FFEB00] hover:text-zinc-900"
            }`}
        >
          Kurumsal
        </button>
        <button
          onClick={() => setActiveTab("Bireysel")}
          className={`p-2 rounded text-sm font-semibold transition-colors duration-300 ${activeTab === "Bireysel"
              ? "bg-[#FFEB00] text-zinc-900"
              : "text-[#FFEB00] hover:bg-[#FFEB00] hover:text-zinc-900"
            }`}
        >
          Bireysel
        </button>
      </div>
      {activeTab == "Kurumsal" ? (
        <div className="flex flex-col">
          <span className="text-[#09090B] font-semibold mt-[28px]">Kişisel Bilgiler</span>
          <div className="mt-[12px] lg:mt-[24px]">
            <Form
              form={form}
              name="myForm"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="grid grid-cols-2 gap-x-[5px] lg:gap-x-[20px]">
                {/* Adı Inputu */}
                <Form.Item
                  label="Adı"
                  name="firstName"
                  rules={[
                    { required: true, message: "Adınızı giriniz!" },
                    { pattern: /^[A-Za-z]+$/, message: "Adınız sadece harflerden oluşmalıdır!" },
                  ]}
                >
                  <Input
                    placeholder="Adınız"
                    className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                  />
                </Form.Item>

                {/* Soyadı Inputu */}
                <Form.Item
                  label="Soyadı"
                  name="lastName"
                  rules={[
                    { required: true, message: "Soyadınızı giriniz!" },
                    { pattern: /^[A-Za-z]+$/, message: "Soyadınız sadece harflerden oluşmalıdır!" },
                  ]}
                >
                  <Input
                    placeholder="Soyadınız"
                    className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                  />
                </Form.Item>

                {/* Doğum Tarihi Inputu */}
              </div>
              <div className="grid lg:grid-cols-3 lg:gap-x-[20px]">
                <Form.Item
                  label="Doğum Tarihi"
                  name="birthDate"
                  rules={[{ required: true, message: "Doğum tarihinizi giriniz!" }]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: "100%" }}
                    className="border-[#E4E4E7] rounded-[12px] p-[20px] -mt-1.5"
                  />
                </Form.Item>

                {/* TCKN Inputu */}
                <Form.Item
                  label="TCKN"
                  name="tckn"
                  rules={[
                    { required: true, message: "TCKN giriniz!" },
                    { validator: tcknValidator },
                  ]}
                >
                  <Input
                    placeholder="11 haneli TCKN"
                    maxLength={11}
                    className="border-[#E4E4E7] rounded-[12px] p-[20px] -mt-1.5"
                  />
                </Form.Item>

                {/* IBAN Inputu */}
                <Form.Item
                  label="IBAN"
                  name="iban"
                  rules={[{ required: true, message: "IBAN giriniz!" }]}
                >
                  <Input
                    placeholder="IBAN"
                    className="border-[#E4E4E7] rounded-[12px] p-[20px] -mt-1.5"
                  />
                </Form.Item>
              </div>
              <div className="h-px w-full bg-[#E4E4E7] mt-[14px] lg:mt-[18px]"></div>
              <div className="flex flex-col">
                <span className="text-[#09090B] font-semibold mt-[14px] lg:mt-[28px] text-base">
                  Adres Bilgileri
                </span>
                <div className="grid grid-cols-2 gap-x-[5px] lg:gap-x-[20px] mt-[12px] lg:mt-[24px]">
                  {/* Adı Inputu */}
                  <Form.Item
                    label="İl"
                    name="city"
                    rules={[
                      { required: true, message: "İl giriniz!" },
                      { pattern: /^[A-Za-z]+$/, message: "İl sadece harflerden oluşmalıdır!" },
                    ]}
                  >
                    <Input
                      placeholder="İl"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>

                  {/* Soyadı Inputu */}
                  <Form.Item
                    label="İlçe"
                    name="district"
                    rules={[
                      { required: true, message: "ilçe giriniz!" },
                      { pattern: /^[A-Za-z]+$/, message: "İlçe sadece harflerden oluşmalıdır!" },
                    ]}
                  >
                    <Input
                      placeholder="İlçe"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>

                  {/* Doğum Tarihi Inputu */}
                </div>
                <Form.Item
                  label="Adresiniz"
                  name="description"
                  rules={[{ required: true, message: "Lütfen bir adres giriniz!" }]}
                >
                  <TextArea
                    rows={2}
                    placeholder="adres buraya giriniz..."
                    className=" border-[#E4E4E7] rounded-[12px] px-[10px]  w-full -mt-1.5"
                  />
                </Form.Item>
              </div>
              {/* Gönder Butonu */}
              <Form.Item className="flex lg:justify-end">
                <button
                  type="primary"
                  htmlType="submit"
                  className="bg-[#FFEB00] rounded-[12px] p-[4px] lg:p-[8px] text-[15px] font-semibold text-[#09090B] w-80 lg:w-[240px] h-[52px]"
                >
                  Kaydet
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <div className="mt-32">Şimdilik Boş</div>
      )}
    </div>
  );
};

export default MyProfileTab;
