import React, { useState } from "react";
import { Steps, Button, Modal, Tooltip, Form, Input, Select, Checkbox } from "antd";
import InputMask from "react-input-mask";
const PremiumTab = () => {
  const [isModalPremiumFormVisible, setIsModalPremiumFormVisible] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Form values:", values);
    handleOk();
  };
  const handleOk = () => {
    setIsModalPremiumFormVisible(false);
  };
  const handleFormModal = () => {
    setIsModalPremiumFormVisible(true)
  }
  const handleCancelForm = () => {
    setIsModalPremiumFormVisible(false)
  }
  const onCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };
  return (
    <div className="flex flex-col mt-5 lg:mt-0">
      <div className="bg-[#FFEB00] flex flex-col lg:flex-row items-center rounded-[16px] h-[228px] p-[17px] lg:p-[17px] lg:gap-x-[18px]">
        <div>
          <img src="/assets/img/blog-banner-car.png" alt="banner-car" />
        </div>
        <div className="flex flex-col lg:ml-[32px]">
          <h6 className="font-medium text-sm max-w-sm lg:max-w-auto lg:text-[24px] lg:leading-8 text-[09090B] lg:w-[434px]">
            Premium ile Provizyon Ödemeden Sınırsız Teklif Verebilirsiniz
          </h6>
          <span className="text-[09090B] text-sm lg:w-[434px] hidden lg:block">
            String Chicago sautéed peppers Philly tomatoes tomato Aussie burnt lovers
          </span>
        </div>
        <button onClick={handleFormModal} className="self-end bg-[#09090B] rounded-[56px] -mt-3 lg:-mt-0 lg:ml-28 text-[#FFEB00] text-sm font-semibold px-[12px] lg:px-[24px] py-3">
          Premium Üye Ol
        </button>
      </div>
      <Modal
        title="Premium Üye Ol"
        open={isModalPremiumFormVisible}
        footer={null}
        onCancel={handleCancelForm}
        className="custom-modal"
      >
        <Form
          form={form}
          name="paymentForm"
          layout="vertical"
          onFinish={onFinish}
          className="mx-5"
        // initialValues={{
        //   il: "İl Seçiniz",
        //   ilce: "İlçe Seçiniz",
        // }}
        >
          {/* Telefon */}
          <div className="bg-[#FFEB00] text-[#09090B] py-2 px-5 rounded-[8px] text-lg text-center font-semibold mb-2.5">PREMIUM</div>
          {/* Aylık Toplam Gelir Bilginiz */}
          <Form.Item
            label="Kart Sahibi"
            name="name"
            className=""
            rules={[{ required: true, message: "Lütfen Kart Sahibi Adını Soyadını Giriniz!" }]}
          >
            <Input placeholder="Kart Sahibi" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
          </Form.Item>
          <Form.Item
            label="Kart Numarası"
            name="cardNo"
            className="-mt-3"
            rules={[
              { required: true, message: "Lütfen Kart Numarası Giriniz" },
              { pattern: /^\d{4} \d{4} \d{4} \d{4}$/, message: "Lütfen Geçerli Bir Kart Numarası Giriniz!" },
            ]}
          >
            <InputMask
              mask="9999 9999 9999 9999"
              placeholder="Kart Numarası Giriniz"
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  type="text"
                  className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]"
                />
              )}
            </InputMask>
          </Form.Item>
          <div className="flex gap-4">
            <Form.Item
              label="Son Kullanma Tarihi"
              name="expiryDate"
              className="-mt-1 w-1/2"
              rules={[
                { required: true, message: "Lütfen Son Kullanma Tarihini Giriniz" },
                { pattern: /^(0[1-9]|1[0-2])\/\d{2}$/, message: "Lütfen Geçerli Bir Tarih Giriniz (AA/YY)!" },
              ]}
            >
              <InputMask
                mask="99/99"
                placeholder="AA/YY"
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    type="text"
                    className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]"
                  />
                )}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="CVV/CVC"
              name="cvc"
              className="-mt-1 w-1/2"
              rules={[{ required: true, message: "Lütfen CVC Giriniz!" }]}
            >
              <Input placeholder="CVC" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
            </Form.Item>

          </div>
          <Form.Item name="kvkk" valuePropName="checked" className="-mt-2">
            <Checkbox onChange={onCheckboxChange}>
              Bilgilerimin doğruluğunu onaylıyorum.
            </Checkbox>
          </Form.Item>
          <div className="grid grid-cols-2 items-center">
            <div className="w-full flex justify-center mb-5">
              <div className="flex flex-col">
                <span className="text-sm text-[#18181C] text-opacity-60 font-semibold">Toplam Tutar</span>
                <span className="font-semibold text-lg text-[#18181C] text-center">2500TL</span>
              </div>
            </div>
            <div>
              <Form.Item className="">
                <Tooltip title={isCheckboxChecked ? "" : "Lütfen KVKK onayı veriniz"}>
                  <button
                    type="primary"
                    htmlType="submit"
                    className="w-full  py-3 rounded-[8px] font-semibold"
                    style={{
                      backgroundColor: isCheckboxChecked ? "#FFEB00" : "#f0f0f0",
                      color: isCheckboxChecked ? "#000000" : "#cccccc",
                    }}
                    disabled={!isCheckboxChecked} // Checkbox işaretli değilse buton disabled olacak
                  >
                    Ödemeyi Yap
                  </button>
                </Tooltip>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <div className="grid grid-cols-1 lg:grid-cols-3 mt-[48px] gap-y-[12px] lg:gap-y-[48px]">
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/car-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Sınırsız Teklif Hakkı</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/valor-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Valör ile Ödeme Esnekliği</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/support-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Özel Satış Temsilcisi Atama</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/card1-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Güvenilir Hızlı Alım Satım</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/star-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Premium’a Özel İhaleler</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/headset-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Özel Çağrı Merkezi</span>
        </div>
      </div>
      <div className="mt-[40px]">
        <div className="flex flex-col gap-y-[16px]">
          <div className="flex items-center gap-2.5">
            <img src="/assets/icons/info-icon.svg" alt="info-icon" />
            <span className="text-sm font-light text-[#09090B]">
              String Chicago sautéed peppers Philly tomatoes.
            </span>
          </div>
          <div className="flex items-center gap-2.5">
            <img src="/assets/icons/info-icon.svg" alt="info-icon" />
            <span className="text-sm font-light text-[#09090B]">
              Aussie burnt lovers. Garlic sausage pizza parmesan sautéed bbq and white.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumTab;
