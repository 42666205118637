import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";

const ProvisionTab = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [provision, setProvision] = useState([
    {
      id: 1,
      title: "Provizyon Ödemesi",
      subTitle: "12.09.2024 - 12:40",
      price: "15.000 TL",
      status: "Onaylandı",
      icon: "provision-payment",
    },
    {
      id: 2,
      title: "Provizyon Yüklemesi",
      subTitle: "12.09.2024 - 12:40",
      price: "15.000 TL",
      status: "Onaylandı",
      icon: "provision-loaded",
    },
    {
      id: 3,
      title: "Provizyon Yüklemesi",
      subTitle: "12.09.2024 - 12:40",
      price: "15.000 TL",
      status: "Onaylandı",
      icon: "provision-loaded",
    },
    {
      id: 4,
      title: "Provizyon Ödemesi",
      subTitle: "12.09.2024 - 12:40",
      price: "15.000 TL",
      status: "Onaylandı",
      icon: "provision-payment",
    },
    {
      id: 5,
      title: "Provizyon Yüklemesi",
      subTitle: "12.09.2024 - 12:40",
      price: "15.000 TL",
      status: "Onaylandı",
      icon: "provision-loaded",
    },
    {
      id: 6,
      title: "Provizyon Ödemesi",
      subTitle: "12.09.2024 - 12:40",
      price: "15.000 TL",
      status: "Onaylandı",
      icon: "provision-payment",
    },
    {
      id: 7,
      title: "Provizyon Yüklemesi",
      subTitle: "12.09.2024 - 12:40",
      price: "15.000 TL",
      status: "Onaylandı",
      icon: "provision-loaded",
    },
  ]);
  // Form gönderildiğinde çağrılacak fonksiyon
  const onFinish = (values) => {
    console.log("Form Values:", values);
    setIsModalVisible(false);
  };

  return (
    <div>
      {provision.length <= 0 ? (
        <div className="flex flex-col justify-center items-center min-h-[400px]">
          <img src="/assets/icons/car-solid-standart.svg" alt="car-icon" />
          <span className="mt-[28px] text-[18px] font-medium text-[#09090B]">
            Henüz bir provizyon eklemediniz
          </span>
          <span className="mt-[12px] text-sm text-[#71717A]">Lorem ipsum dolor</span>
          <button
            className="bg-[#FFEB00] rounded-[56px] py-[5px] px-[20px] mt-[8px] text-sm font-semibold"
            onClick={() => setIsModalVisible(true)}
          >
            Yeni Provizyon Ekle
          </button>
        </div>
      ) : (
        <div className="border rounded-[16px] mt-5 lg:mt-0 p-[12px] lg:p-[24px]">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <h5 className="text-[#09090B] font-semibold">Provizyon Bilgilerim</h5>
            <div className="flex flex-col lg:flex-row lg:items-center gap-2.5 mt-2.5 lg:mt-0">
              <div className="bg-[#F4F4F5] rounded-[56px] py-2.5 px-5 lg:py-[10px] lg:px-[20px] text-sm font-semibold">
                <span className="text-[#71717A] font-light text-sm mr-1">Mevcut Provizyon:</span>
                <span className="font-semibold text-sm text-[#000000]">60.000 TL</span>
              </div>
              <button
                className="bg-[#FFEB00] rounded-[56px] py-2.5 px-5 lg:py-[10px] lg:px-[20px] text-sm font-semibold"
                onClick={() => setIsModalVisible(true)}
              >
                Provizyon Yükle
              </button>
            </div>
          </div>
          <div className="mt-[14px] lg:mt-[28px] flex flex-col gap-[12px]">
            {provision.map((item, id) => (
              <div key={id} className="border border-[#F4F4F5] rounded-[12px] p-[16px] ">
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-[16px]">
                    <img src={`/assets/icons/${item.icon}.svg`} alt="item-icon" />
                    <div className="flex flex-col">
                      <span className="text-[#09090B] text-sm font-medium">{item.title}</span>
                      <span className="text-[#52525B] font-light text-sm mt-[8px]">
                        {item.subTitle}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="font-medium text-sm">{item.price}</span>
                    <span className="text-[#10B981] font-medium">{item.status}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Modal */}
      <Modal
        // title="Provizyon Yükle"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)} // Modalı kapatır
        footer={null} // Varsayılan footer'ı gizler
        className=""
      >
        <div className="py-5 px-5">
          <div className="text-lg font-semibold">Provizyon Yükle</div>

          <div className="flex flex-col border rounded-3xl justify-center mb-6 mt-[12px]">
            {/* Üstte bir görsel */}
            <img src="/assets/img/provision-img.png" alt="card-icon" className="" />
            <div className="flex justify-between items-center p-[16px]">
              <div className="flex flex-col">
                <span className="font-semibold text-[18px] text-[#09090B]">1 Provizyon</span>
                <span className="mt-[8px] text-sm font-light text-[#27272A]">1 Adet Provizyon</span>
              </div>
              <img src="/assets/icons/tt-logo.svg" alt="" className="w-[141px] h-[36px]" />
            </div>
          </div>
          {/* Form */}
          <Form layout="vertical" onFinish={onFinish}>
            {/* Kart Sahibi Input */}
            <Form.Item
              label="Kart Sahibi"
              name="cardHolder"
              rules={[{ required: true, message: "Kart sahibi adını giriniz!" }]}
              className="-mt-2.5"
            >
              <Input placeholder="Kart Sahibi" className="border-[#E4E4E7] rounded-[12px] p-[10px]" />
            </Form.Item>

            {/* Kart Numarası Input */}
            <Form.Item
              label="Kart Numarası"
              name="cardNumber"
              rules={[
                { required: true, message: "Kart numarasını giriniz!" },
                { len: 16, message: "Kart numarası 16 haneli olmalıdır!" },
              ]}
              className="-mt-2.5"
            >
              <Input
                placeholder="Kart Numarası"
                maxLength={16}
                className="border-[#E4E4E7] rounded-[12px] p-[10px]"
              />
            </Form.Item>
            {/* Yan Yana Ay/Yıl ve CVC Input */}
            <Form.Item>
              <Input.Group className="flex items-center gap-2.5 -mt-2">
                <Form.Item
                  label="Son Kullanma Tarihi (AY/YIL)"
                  name="expiryDate"
                  rules={[
                    { required: true, message: "Son kullanma tarihini giriniz!" },
                    {
                      pattern: /^(0[1-9]|1[0-2])\/\d{2}$/,
                      message: "Geçerli bir tarih giriniz! (MM/YY)",
                    },
                  ]}
                  style={{ width: "50%" }}
                >
                  <Input
                    placeholder="MM/YY"
                    maxLength={5}
                    type="number"
                    className="border-[#E4E4E7] rounded-[12px] p-[10px]"
                  />
                </Form.Item>
                {/* CVC Kodu */}
                <Form.Item
                  label="CVC"
                  name="cvc"
                  rules={[
                    { required: true, message: "CVC kodunu giriniz!" },
                    { len: 3, message: "CVC kodu 3 haneli olmalıdır!" },
                  ]}
                  style={{ width: "50%" }}
                >
                  <Input
                    placeholder="CVC"
                    maxLength={3}
                    className="border-[#E4E4E7] rounded-[12px] p-[10px]"
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            {/* Ödemeyi Yap Butonu */}
            <div className="flex items-center gap-2.5">
              <div className="flex flex-col items-center w-[50%] -mt-2">
                <span className="text-sm text-[#27272A]">TOPLAM TUTAR</span>
                <span className="text-[#09090B] text-[20px] font-bold">15.000 TL</span>
              </div>
              <Form.Item className="w-[50%]">
                <Button
                  htmlType="submit"
                  className="w-full bg-[#FFEB00] border-none h-[56px] rounded-[12px] py-[22px] px-[64px] mt-[8px] text-sm font-semibold hover:bg-[#fff] hover:text-[#FFEB00]"
                >
                  Ödemeyi Yap
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ProvisionTab;
