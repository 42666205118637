import React from 'react'
import { Link } from 'react-router-dom'
import { cmsImageUrl } from '../../api/apiControllerCms'

const Bolum2 = ({ data }) => {
  return (
    <div className="mt-[10px] lg:mt-[120px]">
      <div className="flex flex-col-reverse lg:flex-row lg:items-center gap-5 lg:gap-12">
        <div className="flex flex-col w-full lg:w-1/2 py-4 gap-8">
          <div>
            <p className='' dangerouslySetInnerHTML={{ __html: data?.title }}>
            </p>
          <article className=" text-[#52525B] font-light lg:w-[608px] leading-snug">
            {data?.desc}
          </article>
          </div>
          <Link
            to="/"
            className="flex justify-center items-center gap-2 text-sm w-[208px] text-[#09090B] py-[12px] px-[20px] rounded-[40px] bg-[#FFEB00] "
          >
            <span className='font-semibold'>{data?.buttonTitle}</span>
            <img src="/assets/icons/right-arrow.svg" alt="right-arrow-icon" />
          </Link>
        </div>
        <div className="w-full lg:w-1/2">
          <img  src={cmsImageUrl + data?.image?.url} alt="car" className='rounded-[16px]' />
        </div>
      </div>
    </div>
  )
}

export default Bolum2